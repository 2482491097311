<template>
	<div class="content">
		<div style="overflow: hidden">
			<van-sticky>
				<myNarBar class="title">
					<div slot="left" @click="goBack()">
						<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
					</div>
					<div slot="title">新增订单明细</div>
				</myNarBar>
				<van-search
				    v-model="queryData.searchKey"
				    show-action
					shape="round"
				    placeholder="想吃什么搜一搜"
				>
					<template #action>
						<div @click.stop="onSearch()">搜索</div>
					</template>
				</van-search>
			</van-sticky>
		</div>
		<div class="allDiv">
			<div class="leftDiv" :style="{height: srollHeight+'px'}">
				<van-sidebar v-model="activeKey" class="van-sidebar">
				  <van-sidebar-item :title="item.name" v-for="item in fileTypeList" @click="onChangeFileType(item.id)" :key="item.id" />
				</van-sidebar>
			</div>
			<div class="rightDiv" :style="{height: srollHeight+'px'}">
				<div>					
					<van-pull-refresh v-model="loading" @refresh="onRefresh()">
						<div class="contentCard">
							<div class="fileList" v-for="(file, index) in fileList" :key="index" :index="index">
								<van-swipe-cell>
									<div>
										<div>
											<van-card
												:price="file.price"
												:desc="file.remark"
												:title="file.relName"
												:thumb="file.attUrl"
											>
											  <template #footer>
												<i v-if="file.count > 0" class="el-icon-remove" @click="toShopCard(index, file, 'plus')"></i>
												<i v-if="file.count > 0">{{file.count}}</i>													
												<i class="el-icon-circle-plus" @click="toShopCard(index, file, 'add')"></i>
											  </template>
											</van-card>
										</div>
									</div>
								</van-swipe-cell>
							</div>
						</div>
					</van-pull-refresh>
				</div>
			</div>
		</div>
		</van-sticky>
		
		<div class="shopCardClass" v-if="shopCard.total > 0" style="z-index: 998;">
			<div @click="showShopCardDetail = true">
				<span style="float: left; padding: 10px; 0px 0px 10px;">{{shopCard.total}}</span>
				<span style="float: left; padding: 10px; 0px 0px 10px;">¥{{shopCard.totalPrice}}</span>
			</div>
			<span class="buyClass" @click="toOrderConfirm">添加</span>
		</div>
		
		<van-popup v-model="showShopCardDetail" style="z-index: 999;" position="bottom">
			<van-list :finished="finished" finished-text="没有更多了" :offset="10">
				<div class="contentCard1">
					<div class="fileList" v-for="(file, index) in hasChooseFood" :key="index" :index="index">
						<van-swipe-cell>
							<div>
								<div>
									<van-card
									  :price="file.price"
									  :desc="file.remark"
									  :title="file.relName"
									  :thumb="file.attUrl"
									>
									  <template #footer>
									    <i v-if="file.count > 0" class="el-icon-remove" @click="toShopCard(index, file, 'plus')"></i>
									    <i v-if="file.count > 0">{{file.count}}</i>													
									    <i class="el-icon-circle-plus" @click="toShopCard(index, file, 'add')"></i>
									  </template>
									</van-card>
								</div>
							</div>
						</van-swipe-cell>
					</div>
				</div>
			</van-list>
		</van-popup>
	</div>
</template>

<script>
	import {api} from "../../../common/request/api/index";
	import Vue from 'vue';
	import {
		Cascader,ImagePreview,DropdownMenu,DropdownItem,PullRefresh,Toast,SwipeCell,Search,Sidebar,SidebarItem,Card,Popup
	} from 'vant'
	Vue.use(Cascader).use(ImagePreview).use(DropdownMenu).use(DropdownItem).use(Popup)
	.use(PullRefresh).use(Toast).use(SwipeCell).use(Search).use(Sidebar).use(SidebarItem).use(Card);
	import footerTabbar from '../../../components/footerTabbar.vue'
	import myNarBar from '../../../components/myNarBar.vue'
	export default {
		components: {
			footerTabbar,
			myNarBar
		},
		data() {
			return {
				show: false,
				fieldValue: '',
				cascaderValue: '',
				options: [],
				fieldNames: {
					text: 'lovName',
					value: 'id',
					children: 'children',
				},
				list: [],
				
				index: '',
				fieldList: [
					{
						value: "",
					},
					{
						value: "",
					},
					{
						value: "",
					},
				],
				
				fileList: [],
				showBigFile: false,
				
				
				pdfUrl: '',
				showPdf:false,
				isLoading: false,
				loading: false,
				finished: false,
				refreshing: false,
				offset: 20, //滚动条与底部距离小于offset时触发load事件
				queryData: {
					curPage: 0,
					pageSize: 5,
					searchKey: '',
					activeKey: '',
				},
				
				windowWidth: window.innerWidth > 500 ? window.innerWidth : 500,
				windowHeight: window.innerHeight/4,
				srollHeight: window.innerHeight - 100,
				
				pcProgram: navigator.userAgent.toUpperCase().match("MINIPROGRAM") != null && !this.IsMobile(),
				
				fileTypeList: [],
				activeKey: '',
				
				showPreview: false,
				images: [],
				
				shopCard: {
					total: 0,
					totalPrice: 0,
				},
				hasChooseFood: [],
				showShopCardDetail: false,
				orderId: '',
			}
		},
		created() {
			this.queryData.searchKey = this.$route.query.searchKey;
			this.orderId = this.$route.query.orderId;
			this.onLoad()
			
			let _this = this
	        document.onkeydown = function(e){
	            let key = window.event.keyCode
	            if(key == 13){
					_this.queryData.curPage = 0;
					_this.fileList = [];
					_this.finished = false;
					_this.onLoad();
	            }
	        }
		
			this.querySideBarMenu();
			this.hasChooseFood = [];
		},
		methods: {
			onResetQueryData(){
				this.queryData.curPage = 0,
				this.queryData.pageSize = 5,
				this.queryData.searchKey = '',
				this.queryData.activeKey = '',
				
				this.onLoad()
			},
			querySideBarMenu(){
				api.queryFoodCat(this.queryData).then(res => {
					this.fileTypeList = res;
				});
			},
			onChangeFileType(type){
				this.queryData.curPage = 0;
				this.fileList = [];
				this.finished = false;
				this.queryData.activeKey = type;
				this.onLoad()
			},
			goBack() {
				this.$router.go(-1)
			},
			onChange({ value }) {
				console.log(123)
			},
			toShopCard(index, item, type){
				if(type == "add"){
					item.count++
					if(this.hasChooseFood && this.hasChooseFood.length > 0){
						var result = this.hasChooseFood.find(cart => {
							return cart.id == item.id
						})
						if(!result){
							this.hasChooseFood.push(item)
						}
					}else{
						this.hasChooseFood.push(item)
					}
				}else{
					item.count--
					if(item.count == 0){
						// 通过索引删除对象
						if(this.hasChooseFood && this.hasChooseFood.length > 0){
							var result = this.hasChooseFood.find(cart => {
								return cart.id == item.id
							})
							this.hasChooseFood.pop(item)
						}
					}
				}
				this.calcShopCard();
			},
			calcShopCard(){
				let price = 0;
				let total = 0;
				this.hasChooseFood.forEach(item => {
					price += item.count * item.price
					total += item.count
				})
				this.shopCard.totalPrice = price.toFixed(2);
				this.shopCard.total = total;
			},
			onSearch() {
				this.queryData.curPage = 0;
				this.queryData.activeKey = '';
				this.fileList = [];
				this.finished = false;
				this.onLoad()
			},
			onRefresh(type) {
				this.queryData.curPage = 0;
				this.fileList = [];
				this.finished = false;
				
				this.shopCard.total = 0;
				this.shopCard.totalPrice = 0;
				this.onLoad();
			},
			onLoad(){				
				this.queryData.curPage++;
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				api.queryFood(this.queryData).then(res => {
					if (res == null || res.length === 0) {
						//加载结束
						this.finished = true;
					}
					if (res.length < this.queryData.pageSize) {
						//最后一页不足10条的情况
						this.finished = true
					}
					//处理数据
					if (this.queryData.curPage == 1) {
						this.fileList = res
					} else {
						this.fileList = this.fileList.concat(res)
					}
				}, err => {
					Toast(err.data.msg);
					this.finished = true
					this.queryData.curPage--;
				}).finally(() => {
					this.loading = false;
					this.isLoading = false;
					Toast.clear();
				});
			},
			formatDateHMS(time) {
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			toOrderConfirm(){
				this.hasChooseFood.forEach(item => {
						item.createdAt = this.formatDateHMS(item.createdAt)
						item.updatedAt = this.formatDateHMS(item.updatedAt)
				})
				var hasChooseFood = JSON.stringify(this.hasChooseFood);
				console.log(this.orderId)
				
				api.insertOrderDetail(this.orderId, JSON.stringify(this.hasChooseFood)).then(res => {
					Toast(res)
				})
			}
		},
		mounted() {
			this.calcShopCard()
		},
	};
</script>

<style scoped>

	.tilte {
		text-align: left;
		margin: 0px 0px;
		padding: 2px;
		font-size: 16px;
		font-weight: 700;
	}

	.swiper-item {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 3px 3px 0;
	}

	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	
	.contentCard {
		background-color: #f5f5f5;
		height: auto;
	}
	
	.cardDiv {
		margin: 20px;
		font-size: 14px;
	}
	
	.van-image {
		width: 100%;
		height: 100px;
	    background-color: #fff;
	}
	
	.van-card {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    font-size: 12px;
	    background-color: #fff;
	}
	
	.menuName {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		height: 30px;
		background-color: #fff;
		box-shadow: 0 2px 12px rgb(100 101 102 / 12%);
	}
	
	.menuName span {
		padding: 5px 10px;
	}
	
	.download-button {
		width: 100%;
		height: 100%;
	}
	
	.fileList span {
		font-size: 14px;
	}

	.fileList {
		width: 280px;
		background-color: white;
		margin: 10px 5px;
		text-align: left;
		margin-right: 100px;
	}
	
	.van-sidebar {
		width: auto;
	}
	
	.allDiv {
		position: absolute;
		width: 100%;
	}
	.leftDiv {
		float: left;
		width: 20%;
		padding: 10px 0px;
		overflow: hidden;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}
	.rightDiv {
		float: left;
		width: 80%;
		padding: 0px 0px 120px 0px;
		overflow: hidden;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}
	
	.btnTotalItem {
		margin-right: 10px;
		text-align: left;
	}
	
	.shopCardClass {
		background-color: gainsboro;
		z-index: 999999; 
		position: fixed;
		bottom: 0; 
		width: 280px; 
		height: 40px; 
		border-radius: 50px;
		margin: 100px;
	}
	
	.buyClass {
		float: right; 
		padding: 10px 20px 10px 20px; 
		background-color: red;
		margin: 0px 10px;
		border-radius: 50px;
		color: white;
	}
	
	[class*=" el-icon-"], [class^=el-icon-] {
	    font-family: element-icons!important;
	    speak: none;
	    font-style: normal;
	    font-weight: 400;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    vertical-align: baseline;
	    display: inline-block;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    font-size: 25px;
		color: red;
	}
</style>
