import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        networkStatus:true,
        loginData:undefined
    },
    mutations: {
        changeNetworkStatus(state,isLine){
            if(isLine)state.networkStatus = true;
            else state.networkStatus = false;
        }
    }
})