<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">人员</div>
				<div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="addEmployee" />
				</div>
			</myNarBar>
		</van-sticky>
		<div class="content">
			<input v-model="listQuery.name"/>
			<button @click="fetchData">刷新/查询</button>
			<div>
				 <el-table
				    :data="employeeList"
				    stripe
				    style="width: 100%">
					<el-table-column
						prop="img"
						label="营业执照"
						width="120">
						<template slot-scope="scope">
							<van-image
								v-if="scope.row.type == 'out'"
								width="50"
								height="50"
								:src="scope.row.img"
							/>
						</template>
				    </el-table-column>
				    <el-table-column
						prop="name"
						label="姓名"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="shopName"
						label="店铺名称"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="phone"
						label="电话"
						width="150">
				    </el-table-column>
				    <el-table-column
						prop="address"
						label="详细地址"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="pushPerson"
						label="推荐人"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="deleteFlag"
						label="状态"
						width="100">
						<template slot-scope="scope">
							<div slot="reference" class="name-wrapper">
								<el-tag size="medium">
									<span :class="scope.row.deleteFlag == 'Y'?'unValid':'valid'">{{ scope.row.deleteFlag=='N'?'有效':'无效' }}
									</span>
								</el-tag>
							</div>
						</template>
				    </el-table-column>
				    <el-table-column
						prop="type"
						label="类型"
						width="100">
						<template slot-scope="scope">
							<div slot="reference" class="name-wrapper">
								<el-tag size="medium">
									<span :class="scope.row.type == 'out'?'unValid':scope.row.type == 'inner'?'valid':'agent'">{{ scope.row.type=='inner'?'内部':scope.row.type=='out'?'外部':'供应商' }}
									</span>
								</el-tag>
							</div>
						</template>
				    </el-table-column>
				    <el-table-column
						prop="remark"
						label="备注"
						width="100">
				    </el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<div class="btnClass">
								<span @click="handleEdit(scope.$index, scope.row)">编辑</span>
								<span @click="handleSales(scope.$index, scope.row)">折扣</span>
								<span @click="handlePermission(scope.$index, scope.row)">权限</span>
								<span @click="handleDelete(scope.$index, scope.row)">删除</span>
							</div>
						<!-- <van-button
							size="mini"
							@click="handleEdit(scope.$index, scope.row)">编辑</van-button>
						<van-button
							size="mini"
							@click="handleSales(scope.$index, scope.row)">折扣</van-button>
						<van-button
							size="mini"
							@click="handlePermission(scope.$index, scope.row)">权限</van-button>
						<van-button
							size="mini"
							@click="handleDelete(scope.$index, scope.row)">删除</van-button> -->
						</template>
					</el-table-column>
				  </el-table>
				  <el-pagination
				    background
				    layout="prev, pager, next"
				    :total="1000">
				  </el-pagination>
			</div>
			
			<el-dialog
				title="新增/编辑"
				:visible.sync="showAdd"
				width="80%"
				class="dialog-form"
			>
				<van-form @submit="onSubmit">
					<van-field 
						v-model="employee.name" 
						label="姓名" 
						placeholder="请输入姓名" 
						required
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="employee.phone" 
						label="电话" 
						placeholder="请输入电话"
						required
						:rules="[{ required: true }]"
					/>
					<van-radio-group v-model="employee.type" direction="horizontal">
						<van-radio name="inner">内部</van-radio>
						<van-radio name="out">外部</van-radio>
						<van-radio name="agent">供应商</van-radio>
					</van-radio-group>
					<van-field 
						v-model="employee.remark" 
						label="描述" 
						placeholder="请输入描述" 
						type="textarea"
					/>
					<div>
						<van-button round type="info" size="normal">取 消</van-button>
						<van-button round type="info" size="normal" native-type="submit">确 定</van-button>
					</div>
				</van-form>
			</el-dialog>
			
			<el-dialog
				title="权限新增/编辑"
				:visible.sync="showPermission"
				width="80%"
				class="dialog-form"
			>
				<van-checkbox-group v-model="permissions">
				  <van-checkbox v-for="(item, index) in permissionList" :key="index" :name="item.id">{{item.lovName}}</van-checkbox>
				</van-checkbox-group>
				<div>
					<van-button round type="info" size="normal">取 消</van-button>
					<van-button round type="info" size="normal" @click="insertUpdatePermission">确 定</van-button>
				</div>
			</el-dialog>
		</div>
		
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,Tab,Tabs,SwipeCell,Image,Radio,RadioGroup
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(Radio).use(RadioGroup)
	.use(Tab).use(Tabs).use(SwipeCell).use(Image);
	export default {
		name: "userManager",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				employeeList: [],
				listQuery: {
					name: '',
				},
				showAdd: false,
				handelType: '',
				
				employee: {
					id: '',
					name: '',
					phone: '',
					type: '',
					remark: '',
					deleteFlag: '',
				},
				
				showPermission: false,
				permissionList: [],
				permissions: [],
				currUser: '',
			}
		},
		created() {
			this.fetchData();
			
			api.queryLovMember({parentId: '4446d722281b8f4e25dcb5f69fb96e83'}).then(res => {
				this.permissionList = res
			})
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			fetchData() {
			  api.queryEmployee(this.listQuery).then(res => {
			    this.employeeList = res
			    this.listLoading = false
			  }).catch(() => {
			    this.listLoading = false
			  })
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(index, item){
				var deleteFlag =  item.deleteFlag == 'Y'?'N':'Y';
				api.deleteEmployee({id: item.id, deleteFlag: deleteFlag}).then(res => {
					Toast("操作成功")
					this.fetchData();
				})
			},
			addEmployee(){
				this.showAdd = !this.showAdd;
				this.handelType = 'add';
				this.employee.type = "inner"
			},
			handleEdit(index, item){
				this.showAdd = !this.showAdd;
				this.handelType = 'edit';
				this.employee = item;
				this.employee.createdAt = this.formatDateHMS(this.employee.createdAt)
				this.employee.updatedAt = this.formatDateHMS(this.employee.updatedAt)
			},
			handleSales(index, item){
				this.$router.push({
						path: '/crm/userSales',
						query: {
							id: item.id
						}
					});
			},
			handlePermission(index, item){
				this.showPermission = !this.showPermission;
				this.permissions = [];
				api.queryPermission({userId: item.id}).then(res => {
					res.forEach(data => {
						this.permissions.push(data.lovMemberId);
					})
				})
				this.currUser = item
			},
			insertUpdatePermission(){
				console.log(this.permissions)
				api.insertUpdatePermission(this.currUser.id, this.permissions.join(",")).then(res => {
					Toast(res)
					this.showPermission = !this.showPermission;
				})
			},
			handleInsert(index, item){
				this.employee = item;
				this.employee.createdAt = this.formatDateHMS(this.employee.createdAt)
				this.employee.updatedAt = this.formatDateHMS(this.employee.updatedAt)
				this.employeeMethod()
			},
			onSubmit(){
				this.employeeMethod()
			},
			employeeMethod(){
				this.showAdd = !this.showAdd;
				var params = {employee: this.employee}
				api.insertUpdateEmployee(this.employee).then(res => {
					Toast(res)
					this.fetchData();
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.valid {
		color: green;
	}
	.agent {
		color: blue;
	}
	.content {
		overflow-x: hidden;
		padding: 0px 0px 100px;
	}
	.btnClass span {
		padding: 0px 0px 0px 5px;
	}
</style>