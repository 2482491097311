<template>
	<div>
		<div>
			<van-sticky>
				<myNarBar class="narBarTitle">
					<div slot="left" @click="goBack()">
						<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
					</div>
					<div slot="title">图片</div>
					<div slot="right"  @click="add()">
						<van-icon name="plus" size="24" style="vertical-align: middle;" />
					</div>
				</myNarBar>
			</van-sticky>
		</div>
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<div class="content">
					<div class="attachment" v-for="(item, index) in attachment" :index="index" :key="index">
						<div>
							<label>上传时间：</label>{{formatDate(item.createdAt)}}
						</div>
						<van-image :src="item.attUrl" @click="imagePreview(index)"/>
					</div>
			</div>
		</van-pull-refresh>
	</div>
</template>
<script>
	import {
		api
	} from "../common/request/api/index";
	import myNarBar from './myNarBar.vue';
	import Vue from 'vue';
	import {
		ImagePreview, Icon, Dialog, Toast,PullRefresh
	} from 'vant'
	Vue.use(ImagePreview).use(Icon).use(Dialog).use(Toast).use(PullRefresh)
	export default {
		name: "attachment",
		data() {
			return {
				attachment: [],
				imgList: [],
				businessId: '',
				status: false,
				isLoading: false,
				
				cacheAttachment: [],
				sourceDocTp: []
			}
		},
		created() {
			this.businessId = this.$route.query.businessId;
			this.status = this.$route.query.status;
			this.initData();
		},
		methods: {
			queryAttachmentList(){
				api.queryFileSystem({businessId: this.businessId}).then(res => {
					this.attachment = res;
					var a = [];
					if(this.attachment != null && this.attachment.length > 0){
						this.attachment.forEach(function(data){
							a.push(data.attUrl);
						})
					}
					this.imgList = a;
				}).finally(() => {
					this.loading = false;
					this.isLoading = false;
					Toast.clear();
				});
			},
			initData(){
				this.queryAttachmentList();
			},
			formatDate(time) {
				return new Date(time).format("yyyy-MM-dd")
			},
			onRefresh() {
				this.queryAttachmentList({'id': this.id});
			},
			goBack() {
				this.$router.go(-1)
			},
			imagePreview(index){
				ImagePreview({
					images: this.imgList,
					startPosition: index,
				})
			},
			add() {
				this.$router.push({
					path: '/crm/addAttachment',
					query: {
						businessId: this.businessId
					}
				});
			},
			deleteBtn(id, index) {
				Dialog.confirm({
					message: '您确定删除这条记录吗？'
				}).then(() => {
					var params = {
						id: id
					};
					api.deleteUploadFile(params).then(() => {
						this.attachment = this.attachment.filter((item, i) => index != i);
					});
				});
			},
		},
		components: {
			myNarBar
		}
	};
</script>

<style scoped>
	.content {
		background-color: #efefef;
		height: 500px;
		padding: 5px;
	}

	.deleteBtn {
		color: red;
		float: right;
		font-size: 24px;
		padding-right: 10px;
		padding-top: 10px;
	}	
	
	.attachment {
		width: auto;
		background-color: white;
		padding: 5px 5px;
		margin: 10px 0px;
		text-align: left;
	}
	
	.attachment span {
		float: right;
	}
	
	.attachment div {
		margin: 10px;
		text-align: left;
	}
	
	.van-button__content {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		/* height: 100%; */
}
</style>
