<template>
  <div>
    <h3>与服务器断开连接，请检查网络设置</h3>
    <div @click="onRefresh">刷新</div>
  </div>
</template>
<script>
import router from "@/router/index";
import {api} from "../common/request/api/index";
export default {
  data() {
    return {};
  },
  created() {
    if (this.$store.state.networkStatus) {
      console.log(router);
    }
  },
  methods: {
    onRefresh() {
      api.networkTest().then(() => {
        if (this.$store.state.networkStatus) {
          router.replace({
            path: "/",
            query: {
              redirect: router.currentRoute.fullPath
            }
          });
        }
      });
    }
  }
};
</script>