<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">字典管理</div>
				<!-- <div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="addlovMember" />
				</div> -->
			</myNarBar>
		</van-sticky>
		<div class="content1">
			<input v-model="listQuery.name"/>
			<button @click="fetchData">刷新/查询</button>
			<div>
				<div class="menuClass">
					<span class="menuSpan">
						菜单
					</span>
				</div>
				 <el-table
				    :data="parentList"
				    stripe
				    style="width: 100%"
					 @row-click="showChildData">
				    <el-table-column
						prop="id"
						label="ID"
						width="300">
				    </el-table-column>
				    <el-table-column
						prop="lovName"
						label="名称"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="remark"
						label="备注"
						width="200">
				    </el-table-column>
					<el-table-column label="操作">
					  <template slot-scope="scope">
						<el-button v-if="scope.row.lovName == '商品名称'"
							size="mini"
							@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
						<el-button 
							size="mini"
							@click="handleAddChild(scope.$index, scope.row)">新增子项</el-button>
						<el-button v-if="scope.row.lovName == '商品名称'"
							size="mini"
							@click="handleDelete(scope.$index, scope.row,'parent')">删除</el-button>
					  </template>
					</el-table-column>
				  </el-table>
				  <el-pagination
				    background
				    layout="prev, pager, next"
				    :total="1000">
				  </el-pagination>
			</div>
			
			<el-dialog
				title="新增/编辑"
				:visible.sync="showAdd"
				width="80%"
				class="dialog-form"
			>
				<van-form @submit="onSubmit('parent')">
					<van-field 
						v-model="lovMember.lovName" 
						label="名称" 
						placeholder="请输入名称" 
						required
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="lovMember.remark" 
						label="描述" 
						placeholder="请输入描述" 
						type="textarea"
					/>
					<van-field 
						v-model="lovMember.optTxt1" 
						label="optTxt1" 
						placeholder="请输入optTxt1" 
					/>
					<van-field 
						v-model="lovMember.optTxt2" 
						label="optTxt2" 
						placeholder="请输入optTxt2" 
					/>
					<div>
						<van-button round type="info" size="normal">取 消</van-button>
						<van-button round type="info" size="normal" native-type="submit">确 定</van-button>
					</div>
				</van-form>		
			</el-dialog>
			
			<div>
				<div class="menuClass">
					<span class="menuSpan">
						明细项
					</span>
				</div>
				 <el-table
				    :data="childList"
				    stripe
				    style="width: 100%">
				    <el-table-column
						prop="id"
						label="ID"
						width="300">
				    </el-table-column>
				    <el-table-column
						prop="lovName"
						label="名称"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="optTxt1"
						label="商品类型"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="optTxt2"
						label="对应关系"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="remark"
						label="备注"
						width="100">
				    </el-table-column>
					<el-table-column label="操作">
					  <template slot-scope="scope">
						<el-button
							size="mini"
							@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
						<el-button
							size="mini"
							@click="handleDelete(scope.$index, scope.row,'child')">删除</el-button>
					  </template>
					</el-table-column>
				  </el-table>
				  <el-pagination
				    background
				    layout="prev, pager, next"
				    :total="1000">
				  </el-pagination>
			</div>
			
			<el-dialog
				title="新增/编辑"
				:visible.sync="showAdd1"
				width="80%"
				class="dialog-form"
			>
				<van-form @submit="onSubmit('child')">
					<van-field 
						v-model="lovMember1.lovName" 
						label="名称" 
						placeholder="请输入名称" 
						required
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="lovMember1.parentId" 
						label="父ID" 
						placeholder="请输入名称" 
						required
						disabled
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="lovMember1.optTxt1" 
						label="optTxt1" 
						placeholder="请输入optTxt1" 
					/>
					<van-field 
						v-model="lovMember1.remark" 
						label="描述" 
						placeholder="请输入描述" 
						type="textarea"
					/>
					<div>
						<van-button round type="info" size="normal">取 消</van-button>
						<van-button round type="info" size="normal" native-type="submit">确 定</van-button>
					</div>
				</van-form>		
			</el-dialog>
		</div>
		
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,DropdownMenu,DropdownItem,Tab,Tabs,SwipeCell,Image 
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(DropdownMenu)
	.use(DropdownItem).use(Tab).use(Tabs).use(SwipeCell).use(Image);
	export default {
		name: "lovMemberTab",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				parentList: [],
				childList: [],
				listQuery: {
					name: '',
					parentId: '',
				},
				showAdd: false,
				showAdd1: false,
				handelType: '',
				
				lovMember: {
					id: '',
					lovName: '',
					optTxt1: '',
					remark: '',
				},
				lovMember1: {
					id: '',
					parentId: '',
					lovName: '',
					optTxt1: '',
					remark: '',
				},
			}
		},
		created() {
			this.fetchData();
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			fetchData(type) {
				if(type != 'child'){
					this.listQuery.parentId = ''
				}
				api.queryLovMember(this.listQuery).then(res => {
					if(type == "child"){
						this.childList = res
					}else{
						this.parentList = res
					}
					this.listLoading = false
				}).catch(() => {
					this.listLoading = false
				})
			},
			showChildData(item, index){
				this.listQuery.parentId = item.id
				this.fetchData("child")
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(index, item, type){
				var deleteFlag =  item.deleteFlag == 'Y'?'N':'Y';
				api.deleteLovMember({id: item.id, deleteFlag: deleteFlag}).then(res => {
					Toast("操作成功")					
					if(type == "child"){
						this.fetchData('child');
					}else{
						this.fetchData();
					}
				})
			},
			addlovMember(){
				this.showAdd = !this.showAdd;
				this.handelType = 'add';
			},
			handleEdit(index, item){
				this.showAdd = !this.showAdd;
				this.lovMember = item;
				this.lovMember.createdAt = this.formatDateHMS(this.lovMember.createdAt)
				this.lovMember.updatedAt = this.formatDateHMS(this.lovMember.updatedAt)
			},
			handleAddChild(index, item){
				this.showAdd1 = !this.showAdd1;
				this.lovMember1.parentId = item.id;
			},
			handleInsert(index, item){
				this.lovMember = item;
				this.lovMember.createdAt = this.formatDateHMS(this.lovMember.createdAt)
				this.lovMember.updatedAt = this.formatDateHMS(this.lovMember.updatedAt)
				this.lovMemberMethod()
			},
			onSubmit(type){
				this.lovMemberMethod(type)
			},
			lovMemberMethod(type){
				var lo = this.lovMember
				if(type == "child"){
					lo = this.lovMember1
				}
				api.insertUpdateLovMember(lo).then(res => {
					Toast("操作成功")
					if(type == "child"){
						this.showAdd1 = !this.showAdd1;
						this.fetchData('child');
					}else{
						this.showAdd = !this.showAdd;
						this.fetchData();
					}
				}, err => {
					if (err) tip(err.data.msg);
				})
			}
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.content {
	}
	.content1 {
		overflow-x: hidden;
		padding: 0px 0px 100px;
	}
	.menuClass {
		text-align: left;
		margin-left: 20px;
	}
	.menuSpan {
		font-weight: 900;
		background-color: antiquewhite;
		padding: 0px 10px 10px 10px;
	}
</style>