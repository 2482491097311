<template>
	<div>
		<!-- 头部 -->
		<div>
			<myNarBar>
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">后台管理</div>
			</myNarBar>
		</div>

		<!-- 内容 -->
		<div>
			<commonUsed></commonUsed>
		</div>
		<div style="width: 100%; height: 10px; background-color: #eee;"></div>

		<!-- 底部 -->
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue';
	import {
		List,
		NavBar
	} from 'vant';
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import commonUsed from './commonUsed.vue'
	Vue.use(List).use(NavBar);
	import { Search,Toast,Form,Field,Popup,Picker,Dialog,Calendar } from 'vant'
	Vue.use(Search).use(Toast).use(Form).use(Field).use(Popup).use(Picker).use(Dialog).use(Calendar)
	export default {
		data() {
			return {
				data:{},
			};
		},
		created() {
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			toNextPage(url){
				console.log(url)
				this.$router.push({
					path: url,
				});
			},
		},
		components: {
			footerTabbar,
			myNarBar,
			commonUsed,
		}
	};
</script>

<style scoped>
	.title1 {
		text-align: left;
		margin: 0px 0px;
		padding: 5px 20px;
		font-size: 16px;
		font-weight: 700;
	}

	.box-card {
		background-color: #fff;
		padding-top: 3px;
	}
	
	.boxContent {
		width: auto;
		margin: 10px;
		margin-top: 32rpx;
		padding: 10px;
		padding-bottom: 16px;
		border: 2px solid #dcdcdc;
		box-shadow: #adadad 0px 0px 4px;
		border-radius: 8px;
	}
	
	.xskhtj{
		width: 100%;
		min-height: 272rpx;
		padding: 16rpx;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	
	.xskhCard{
		width: 25%;
		font-size: 30rpx;
		margin-bottom: 6rpx;
		padding-bottom: 12rpx;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.xskhCard>div:first-child{
		font-weight: bold;
		margin-bottom: 16rpx;
	}
	
	.noneClass {
		color: #007AFF;
	}
	
	.redClass {
		color: #ff0000;
	}
	
	.xskhCard>div:last-child{
		font-weight: bold;
	}
</style>