<template>
	<div class="content">
		<div>
			<van-sticky>
				<myNarBar class="title">
					<div slot="title">我的订单</div>
				</myNarBar>
			</van-sticky>
		</div>
		<div>
			<van-pull-refresh v-model="isLoading" @refresh="queryMyOrder">
				<van-tabs v-model="activeName" @click="queryMyOrder">
					<van-tab title="全部" name="全部">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span style="float: right;font-size: 14px;padding-right: 10px;">{{item.STATUS}}</span>
								</div>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image 
										:src="item.attUrl" 
										height="50" 
										width="50" >
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
					<van-tab title="待支付" name="待支付">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span style="float: right;font-size: 14px;padding-right: 10px;">{{item.STATUS}}</span>
									<span v-if="item.STATUS == '货到付款' && item.PAY_STATUS == 'noPay'" class="btnClass" @click="toInvoice(item, index)">开票链接</span>
								</div>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  总金额：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
									<span v-if="item.SALES" style="color: red;">  {{item.SALES}}折</span>
									<span v-if="item.SALES" style="font-size: 16px;font-weight: 600;">  共：{{(item.PRICE*item.SALES/10).toFixed(2)}}</span>
									<span  v-if="item.STATUS == '待支付'" class="buyClass" @click="toPay(item.ID)">微信支付</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image 
										:src="item.attUrl" 
										height="50" 
										width="50" >
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
					<van-tab title="待发货" name="待发货">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span style="float: right;font-size: 14px;padding-right: 10px;">{{item.STATUS}}</span>
								</div>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>							
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
										<div>{{item.foodCatName}}</div>
										<div>单价：{{item.price}}</div>
										<div>数量：{{item.count}}</div>
										<div v-if="item.sales">折扣：{{item.sales}}</div>
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
					<van-tab title="已发货" name="已发货">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span style="float: right;font-size: 14px;padding-right: 10px;">{{item.STATUS}}</span>
									<span v-if="item.STATUS == '货到付款'" class="btnClass" @click="toInvoice(item, index)">开票链接</span>
								</div>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image 
										:src="item.attUrl" 
										height="50" 
										width="50" >
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
				</van-tabs>
			</van-pull-refresh>
		</div>
		
		<div style="padding-top: 90px; z-index: 999999;">
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import {api} from "../../common/request/api/index";
	import Vue from 'vue';
	import {
		Tab,Tabs,PullRefresh,Toast,Image
	} from 'vant'
	Vue.use(Tab).use(Tabs).use(PullRefresh).use(Toast).use(Image);
	import footerTabbar from '../../components/footerTabbar.vue'
	import myNarBar from '../../components/myNarBar.vue'
	export default {
		components: {
			footerTabbar,
			myNarBar
		},
		data() {
			return {
				isLoading: false,
				activeName: '全部',
				orderList: [],
			}
		},
		created() {
			this.queryMyOrder()
			
			api.queryWxTicket().then(res => {
				wx.config({
					debug: false,
					appId: res.appId,
					timestamp: res.timestamp,
					nonceStr: res.nonceStr,
					signature: res.signature,
					jsApiList: ["chooseWXPay"]
				});
				wx.ready(function(){
					Toast("接入微信成功")
				});
				wx.error(function(){
					Toast("接入微信失败")
				});
				Toast.clear();
			});
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			queryMyOrder(type){
				api.queryMyOrder({status: type}).then(res => {
					this.orderList = res
					this.isLoading = false
				})
			},
			formatDate(time) {
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			toPay(orderId){
				let that = this
				/* api.queryWxPay({orderId: orderId}).then(res => {
					wx.ready(function(){
						Toast("接入微信成功")
						wx.chooseWXPay({
								appId: res.appId, // 支付签名时间戳，注意微信js sdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
								timestamp: res.timeStamp, // 支付签名时间戳，注意微信js sdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
								nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
								package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
								signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
								paySign: res.paySign, // 支付签名
								success: function (res) {
									console.log(res)
									that.doWxPayCallback(recordOrderGid);
								},
								fail: function () {
									that.doWxPayCallback(recordOrderGid);
									console.info('取消支付，如需支付请继续。');
								},
								cancel: function () {
									console.info('取消支付，如需支付请继续。');
								}
							});
					});
					wx.error(function(){
						Toast("接入微信失败")
					});
					Toast.clear();
				}); */
				// wx.miniProgram.navigateTo({url: "/pages/pay/pay"})
				var token = localStorage.getItem("token");
				wx.miniProgram.navigateTo({url: "/pages/pay/pay?token="+encodeURIComponent(token)+"&url=https://szshcy.cn:8000/b-cloud/wx/queryWxPay.loginFree"+"&orderId="+encodeURIComponent(orderId)});
			},
			toInvoice(item, index){
				const link = "https://szshcy.cn/#/crm/print?orderId="+item.ID;
				// 创建临时 <textarea> 元素，将链接写入其中
				const textArea = document.createElement("textarea");
				textArea.value = link;
				document.body.appendChild(textArea);
				 
				// 选中并复制链接
				textArea.select();
				document.execCommand("copy");
				 
				// 将 <textarea> 元素删除
				document.body.removeChild(textArea);
				// window.location.href = 'https://szshcy.cn/#/crm/print?orderId='+item.ID
				Toast("复制成功，请在网页上打开")
			}
		},
		mounted() {
		},
	};
</script>

<style scoped>
	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    font-size: 14px;
	    background-color: #fff;
		margin: 10px;
		border-radius: 5px;
	}
	
	.fileList span {
		font-size: 14px;
	}
	
	.buyClass {
		float: right; 
		padding: 3px 10px; 
		background-color: red;
		margin: 0px 10px;
		border-radius: 50px;
		font-size: 10px;
		color: white;
	}
	
	.btnClass {
		float: right;
		font-size: 14px;
		padding: 0px 5px; 
		margin-right: 10px;
		border-radius: 5px;
		color: white;
		background-color: royalblue;
	}
</style>
