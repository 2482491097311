<template>
	<div>

		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">注册</div>
			</myNarBar>
		</van-sticky>
		
		<div class="fontClass">
			欢迎加入成为我司的订货商，注册成功后，即可了解商品订货价格，并可直接在线下单订货；我们保证：1.优质的货源 2.优惠价格 3.良好售后
		</div>

		<div>
			<van-form @submit="onSubmit">
				<van-field
					v-model="employee.shopName"
					label="店铺名称"
					required
					:rules="[{ required: true, message: '请填写店铺名称' }]"
				/>
				<van-field
					v-model="employee.name"
					label="联系人"
					required
					:rules="[{ required: true, message: '请填写联系人' }]"
				/>
				<van-field
					v-model="employee.phone"
					label="电话"
					required
					:rules="[{ required: true, message: '请填写电话' }]"
				/>
				<van-field
					v-model="employee.userPassword"
					label="密码"
					required
					:rules="[{ required: true, message: '请填写密码' }]"
				/>
				<van-field
					v-model="employee.address"
					label="详细地址"
					required
					:rules="[{ required: true, message: '请填写详细地址' }]"
				/>
				<van-field
					v-model="employee.pushPerson"
					label="推荐人"
				/>
				<van-field
					label="营业执照"
					required
					:rules="[{ required: true, message: '请上传营业执照' }]"
				>
					<template #input>
						<van-uploader v-model="fileList"/>
					</template>
				</van-field>
				<div style="margin: 16px;">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</div>

	</div>
</template>
<script>
	import {
		api
	} from "../common/request/api/index";
	import myNarBar from '../components/myNarBar.vue'
	import {Toast
	} from 'vant';
	import Vue from 'vue';
	Vue.use(Toast);
	export default {
		name: 'register',
		data() {
			return {
				employee: {
					id: '',
					shopName: '',
					name: '',
					phone: '',
					type: 'out',
					userPassword: '',
					address: '',
					pushPerson: '',
				},
				fileList: [],
			};
		},
		created() {},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			onSubmit(){
				this.registerEmployee()
			},
			registerEmployee(){
				if( this.fileList.length == 0){
					Toast("营业执照不允许为空")
				}else{
					var params = {employee: this.employee, fileList: this.fileList}
					api.insertUpdateEmployeeOut(params).then(res => {
						Toast("操作成功")
						this.goBack()
					}, err => {
						if (err) tip(err.data.msg);
					})
				}
			}
		},
		components: {
			myNarBar
		}
	};
</script>

<style scoped>
	
	.fontClass {
		margin: 20px;
		text-align: left;
		line-height: 1.5;
	}
</style>
