<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">库存日志</div>
			</myNarBar>
		</van-sticky>
		<div class="content">
			<input v-model="listQuery.name" />
			<button @click="fetchData">刷新/查询</button>
			<div>
				<el-table :data="employeeList" stripe style="width: 100%">
					<el-table-column prop="outWarehouseName" label="调出仓库/订单号" width="120">
					</el-table-column>
					<el-table-column prop="outFoodName" label="调出商品" width="120">
					</el-table-column>
					<el-table-column prop="inWarehouseName" label="调入仓库" width="100">
					</el-table-column>
					<el-table-column prop="inFoodName" label="调入商品" width="100">
					</el-table-column>
					<el-table-column prop="outSourceAmount" label="调出原数量" width="100">
					</el-table-column>
					<el-table-column prop="outNowAmount" label="调出现数量" width="100">
					</el-table-column>
					<el-table-column prop="inSourceAmount" label="调入原数量" width="100">
					</el-table-column>
					<el-table-column prop="inNowAmount" label="调入现数量" width="100">
					</el-table-column>
					<el-table-column prop="createdByName" label="操作人" width="100">
					</el-table-column>
					<el-table-column prop="createdAt" label="操作时间" width="200">
					</el-table-column>
				</el-table>
				<el-pagination 
					background
					:pager-count=5
					class="paginationClass"
					layout="prev, pager, next"
					@current-change="handleCurChange" 
					@click=""
					:total="1000">
				</el-pagination>
			</div>
		</div>

		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {
		api
	} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,
		Popup,
		Form,
		Field,
		PullRefresh,
		Toast,
		List,
		Dialog,
		Tab,
		Tabs,
		SwipeCell,
		Image,
		Radio,
		RadioGroup
	} from 'vant';
	import {
		tip
	} from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
		.use(Toast).use(List).use(Dialog).use(Radio).use(RadioGroup)
		.use(Tab).use(Tabs).use(SwipeCell).use(Image);
	export default {
		name: "userManager",
		components: {
			myNarBar,
			addBtn,
			footerTabbar
		},
		data() {
			return {
				listLoading: true,
				employeeList: [],
				listQuery: {
					search: '',
					curPage: '1',
					pageSize: '10',
				},
			}
		},
		created() {
			this.fetchData();

		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			handleCurChange(val) {
				this.listQuery.curPage = val;
				this.fetchData();
			},
			fetchData() {
				api.queryWarehouseLog(this.listQuery).then(res => {
					this.employeeList = res
					this.employeeList.forEach(item => {
						item.createdAt = this.formatDateHMS(item.createdAt)
					})
					console.log(this.employeeList)
					this.listLoading = false
				}).catch(() => {
					this.listLoading = false
				})
			},
			formatDate(time) {
				if (null == time || "" == time) {
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if (null == time || "" == time) {
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			onSubmit() {
				this.employeeMethod()
			},
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}

	.valid {
		color: green;
	}

	.agent {
		color: blue;
	}

	.content {
		overflow-x: hidden;
		padding: 0px 0px 100px;
	}

	.btnClass span {
		padding: 0px 0px 0px 5px;
	}
</style>