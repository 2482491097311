<template>
	<div id="app" class="cont">
		<!-- <v-touch v-on:swipeleft="swiperleft" v-on:swiperight="swiperright" class="wrapper"> -->
		<!-- <router-view v-if="isRouterAlive"></router-view> -->
		<!-- <NotNetwork v-if="!this.$store.state.networkStatus"></NotNetwork> -->
		<!-- </v-touch> -->
		
		<keep-alive>
		    <!-- 需要缓存的视图组件 -->
		  <router-view v-if="$route.meta.keepAlive">
		  </router-view>
		</keep-alive>
		
		<!-- 不需要缓存的视图组件 -->
		<router-view v-if="!$route.meta.keepAlive">
		</router-view>
	</div>
</template>
<script>
	import NotNetwork from "@/pages/NotNetwork.vue";
	import {
		api
	} from "@/common/request/api/index";
	import Vue from 'vue';
	import {
		Toast,Dialog
	} from 'vant'
	Vue.use(Toast).use(Dialog);
	let BMap = window.BMap;
	export default {
		name: "App",
		data() {
			return {
				isRouterAlive: true,
			}
		},
		provide() {
			return {
				reload: this.reload
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.isRouterAlive = true
				})
			},
		},
		created() {
			var rememberCheck = localStorage.getItem("rememberCheck");
			if(rememberCheck){
			}
			
			wx.miniProgram.navigateTo({url: "/pages/message/message"})
		},
		components: {
			NotNetwork,
		},
	};
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	.cont{
		width: 100%;
		height: 100%;
	}
</style>
