<template>
	<div class="content">
		<h4 class="title">登录云+</h4>
		<van-form @submit="onSubmit" @failed="userValid=false; PasswordValid=false;">
			<van-field 
				v-model="username" 
				name="username" 
				left-icon="user-o" 
				:placeholder="userValid?'用户名':'请输入用户名'"
				@blur="userValid=false;" 
				@clear="userValid=true;" 
				:rules="[{ required: true}]"
			/>			
			<van-field 
				v-model="password" 
				:type="switchPassType ? 'password' : 'text'" 
				name="password" 
				:left-icon="'lock'" 
				:placeholder="PasswordValid?'密码':'请输入密码'" 
				@blur="PasswordValid=false;" 
				@clear="PasswordValid=true;"
				:right-icon="switchPassType ? 'closed-eye' : 'eye-o'" 
				@click-right-icon="switchPassType=!switchPassType"
				:rules="[{ required: true }]" 
			/>
			<div style="margin: 5px; padding-left: 10px; float: left; font-size: 14px;text-align:left;">
				<a @click="register()">注册</a>
			</div>
			<div style="margin: 5px; padding-right: 10px; float: right; font-size: 14px;text-align:right;">
				<van-checkbox icon-size="15px" v-model="rememberPassword">记住密码</van-checkbox>&nbsp;&nbsp;&nbsp;
			</div>
			<div style="margin: 0px;text-align:right;">
				<van-field name="checkbox" label="">
					<template #input>
						<van-checkbox v-model="rememberCheck">我已阅读并同意</van-checkbox><a @click="goto()" style="color: #409eff;">隐私保护政策</a>
					</template>
				</van-field>
			</div>
			<div style="margin: 16px;">
				<van-button round block type="info" native-type="submit" class="UniAppJSBridgeReady">登录</van-button>
			</div>
		</van-form>
	</div>
</template>
<script>
	import {api} from "../common/request/api/index";
	import Vue from 'vue';
	import {Toast} from 'vant';
	Vue.use(Toast);
	let BMap = window.BMap;
	export default {
		data() {
			return {
				username: "",
				password: "",
				rememberPassword: true,
				rememberCheck: false,
				userValid: true,
				PasswordValid: true,
				province: '', //省
				city: '', //市
				district: '', //区
				address: '', //详细地址
				lng: '', //经度
				lat: '', //维度
				
				switchPassType: true,
				
				showRegister: false,
				
				account: [],
				accountName: [],
				
				code: '',
				
				targetUrl: "http://localhost:8080/#/pages/auth/index?empno=",
				showAuthPermission: true,
				
				show: false,
				showSengAuthCode: true,
				orderNum: 180,
				authCode: '',
			};
		},
		created() {
			var code = window.location.search.split("=")[2];
			this.code = code;
			this.username = localStorage.getItem("username");
			this.password = localStorage.getItem("password");
		},
		methods: {
			goto(){
				this.$router.push({
					path: '/save'
				});
			},
			register(){
				this.showRegister = !this.showRegister
				this.$router.push({
					path: '/register'
				});
			},
			sendMessage(){
				if(this.employee.phone == ''){
					Toast("手机号为空")
				}else{
			        this.showSengAuthCode = false;
					let that = this;
					api.sentSms({sendMessageNo: this.employee.phone}).then(res => {
						this.countDown(that);
					})
				}
			},
			countDown(that) {
			    if (that.orderNum == 0) {
			        that.showSengAuthCode = true;
			        return;
			    }
			    setTimeout(function(){
			      that.orderNum = that.orderNum - 1;
			      that.countDown(that)
			      },
			    1000);
			},
			onSubmit(values) {
				if(this.rememberCheck == false){
					this.$tip("登录失败，请勾选用户隐私策略");
				}else{
					api.login(values.username, values.password).then(res => {
						if(res.passwordNotice){
							this.$tip(res.passwordNotice);
						}
						this.$router.replace("/");
						
						if(this.rememberPassword){
							localStorage.setItem("username", this.username);
							localStorage.setItem("password", this.password);
						}
						localStorage.setItem("userObject", JSON.stringify(res.employee));
						localStorage.setItem("rememberPassword", this.rememberPassword);
						localStorage.setItem("rememberCheck", this.rememberCheck);
						localStorage.setItem("menuPermission", JSON.stringify(res.menuPermission));
						
						var wxOpenId = res.employee.wxOpenId == null ? true : false;
						if(wxOpenId){
							wx.miniProgram.navigateTo({url: "/pages/auth/auth?token="+encodeURIComponent(res.token)+"&url=https://szshcy.cn:8000/b-cloud/wx/jscode2session.loginFree"})
						}
						wx.miniProgram.navigateTo({url: "/pages/message/message"})
					});
				}
			},
		},
	};
</script>

<style scoped>
	.title {
		color: #3193EF;
	}

	.content {
		margin-top: 50px;
	}

	.vanPopup {
		height: 40%;
		width: 50%;
	}
</style>
