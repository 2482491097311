<template>
	<div>
		<div class="button-box" @click="gotoAdd">
			<div class="font-box">
				<van-icon name="plus" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'addBtn',
		props: {
			url: {
				type: String
			}
		},
		data() {
			return {
				
			};
		},
		methods: {
			gotoAdd() {
				if(this.url) {
					this.$router.push({
						path: this.url,
					});
				}
			}
		}
	}
</script>

<style scoped>

	.btn-bg-img {
		width: 80px;
		height: 80px;
		background-size: cover;
	}

	.button-box:hover {
		color: white;
		opacity: 1;
	}
	
	.button-box {
		width: 50px;
		border-radius: 50%;
		position: fixed;
		bottom: 50px;
		right: 50px;
		padding-left: 15px;
		padding-top: 8px;
		cursor: pointer;
		opacity: 0.7;
		z-index: 888;
	}

	.font-box {
		width: 50px;
		color: #ffffff;
		text-align: center;
		font-size: 30px;
		background-color: #0055ff;
		border-radius: 50%;
	}
	.van-icon {
		position: relative;
		display: inline-block;
		font: normal normal normal 14px/1 vant-icon;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
</style>
