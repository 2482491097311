import router from "./index";

router.beforeEach((to,from,next)=>{
    if(to.matched.some(res=>res.meta.isLogin)){
        if (localStorage['userObject']) {
            next();
        }else{
            next({
                path:"/login",
                query:{
                    redirect:to.fullPath
                }
            });
        }
    }else{
        next()
    }
});

export default router;