<template>
	<div class="content">
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">供应商进货</div>
				<div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="agentInsert" />
				</div>
			</myNarBar>
		</van-sticky>
		<div class="contentClass">
			<input placeholder="请输入订单号" v-model="listQuery.comeNo"/>
			<van-button 
				style="margin-right: 10px;" 
				type="info" size="mini" 
				@click="refleshFetchData(null)">查询
			</van-button>
			<van-button 
				style="margin-right: 10px;" 
				type="info" size="mini" 
				@click="refleshFetchData('待付款')">待付款
			</van-button>
			<van-button 
				style="margin-right: 10px;" 
				type="info" size="mini" 
				@click="refleshFetchData('已付款')">已付款
			</van-button>
			<van-pull-refresh v-model="isLoading" @refresh="refleshFetchData">
				<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="40" @load="fetchData">
					<div class="cardClass" v-for="(item,index) in comemeterList" :key="index">
						<div>
							<div style="text-align: left; font-weight: 700; padding-left: 10px;">
								{{item.comeNo}}
								<span style="background-color: aquamarine; border-radius: 5px; padding: 0px 3px;">{{item.status}}</span>
							</div>
							<div style="float: right;padding-right: 10px;">
								<van-button 
									v-if="item.status == '输入'" 
									style="margin-right: 10px;" 
									type="danger" size="mini" 
									@click="handleDelete(item,index)">删除
								</van-button>
								<van-button 
									v-if="item.status == '输入'" 
									style="margin-right: 10px;" 
									type="info" size="mini" 
									@click="agentUpdate(item,index)">入库
								</van-button>
								<van-button 
									v-if="Permission('d4a741f4a49563a0798f6cf972bb9533') && item.hasAccount != 'Y'" 
									style="margin-right: 10px;" 
									type="info" size="mini" 
									@click="comemeterHeaderHasAccount(item.id,index)">到账确认
								</van-button>
								<van-button 
									v-if="Permission('d4a741f4a49563a0798f6cf972bb9533') && item.payStatus != '已付款'" 
									style="margin-right: 10px;" 
									type="info" size="mini" 
									@click="updateComemeterHeaderStatusAgent(item.id,item.payStatus)">已付款
								</van-button>
							</div>
						</div>
						<div>
							<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
								{{item.name}}
							</div>
							<div v-if="item.amount" style="text-align: left;padding-top: 2px;padding-left: 10px;">
								总数：{{item.amount}}
							</div>
							<div v-if="item.price"  style="text-align: left;padding-top: 2px;padding-left: 10px;">
								总价：{{item.price}}
							</div>
							<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
								{{item.createdAt}}
							</div>
						</div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;"
							@click.stop="goDetail(item.id, item.status)">
							<label>明细</label>
							<van-icon class="icon" name="arrow" />
							<hr />
						</div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;"
							@click.stop="goPhote(item.id)">
							<label>图片</label>
							<van-icon class="icon" name="arrow" />
							<hr />
						</div>
					</div>
				</van-list>
			</van-pull-refresh>
		</div>		
		<!-- 底部 -->
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,DropdownMenu,DropdownItem,Tab,Tabs,SwipeCell 
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(DropdownMenu)
	.use(DropdownItem).use(Tab).use(Tabs).use(SwipeCell)
	export default {
		name: "agentCome",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				isLoading: false,
				loading: false,
				finished: false,
				offset: 20, //滚动条与底部距离小于offset时触发load事件
				comemeterList: [],
				listQuery: {
					curPage: '1',
					pageSize: '10',
					comeNo: '',
					payStatus: '',
				},
			}
		},
		created() {
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			refleshFetchData(payStatus){
				this.comemeterList = [];
				this.listQuery.curPage = 1;
				this.listQuery.payStatus = payStatus;
				this.finished = false;
				this.loading = true;
				this.fetchData();
			},
			fetchData() {
				let that = this
				api.queryComemeterHeaderAgent(this.listQuery).then(res => {
					res.forEach(item => {
						item.createdAt = that.formatDateHMS(item.createdAt)
						item.updatedAt = that.formatDateHMS(item.updatedAt)
					})
					
					if (res == null || res.length === 0) {
						//加载结束
						this.finished = true;
					}
					if (res.length < this.pageSize) {
						//最后一页不足10条的情况
						this.finished = true
					}
					//处理数据
					if (this.listQuery.curPage == 1) {
						this.comemeterList = res
					} else {
						this.comemeterList = this.comemeterList.concat(res)
					}
					this.listQuery.curPage++;
				}, err => {
					Toast(err.data.msg);
					this.finished = true
				}).finally(() => {
					this.loading = false;
					this.isLoading = false;
					Toast.clear();
				});
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(item, index){
				var deleteFlag =  item.deleteFlag == 'Y'?'N':'Y';
				api.deleteComemeterHeader({id: item.id, deleteFlag: deleteFlag}).then(res => {
					Toast("操作成功")
					// this.comemeterList = [];
					// this.listQuery.curPage = 1;
					// this.finished = false;
					// this.fetchData();
					this.comemeterList = this.comemeterList.filter((item, i) => index != i);
				})
			},
			agentInsert(){
				api.insertComemeterHeader().then(res => {
					Toast("操作成功,请下拉刷新")
					this.comemeterList = [];
					this.listQuery.curPage = 1;
					this.finished = false;
					// this.fetchData();
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
			agentUpdate(item){
				
				api.updateComemeterHeader(item).then(res => {
					Toast(res)
					item.status = '入库'
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
			comemeterHeaderHasAccount(id){
				api.comemeterHeaderHasAccount({id: id}).then(res => {
					Toast(res)
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
			updateComemeterHeaderStatusAgent(id,payStatus){
				api.updateComemeterHeaderStatusAgent({id: id}).then(res => {
					Toast(res)
					this.refleshFetchData()
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
			goDetail(headerId, status){
				this.$router.push({
					path: '/crm/agentComeDetail',
					query: {
						headerId: headerId,
						status: status
					}
				});
			},
			goPhote(headerId){
				this.$router.push({
					path: '/crm/attachment',
					query: {
						businessId: headerId
					}
				});
			}
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.contentClass {
		margin: 0px 0px 80px 0px;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    background-color: #fff;
		margin: 5px;
		border-radius: 5px;
	}
	.cardClass label {
		text-align: left;
	}
	.icon {
		float: right;
		padding: 2.5px;
	}
</style>
