import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import login from "../components/Login";
import register from "../components/register";
import save from "../components/save";
import error from "../components/error";

/**
 * 底部
 */
import index from "../pages/home/index.vue";
import shop from "../pages/home/shop.vue";
import orderConfirm from "../pages/home/orderConfirm.vue";
import orderPaying from "../pages/home/orderPaying.vue";
import addressList from "../pages/home/addressList.vue";
import order from "../pages/home/order.vue";
import person from "../pages/home/person.vue";
import backManager from "../pages/home/system/backManager.vue";


import commonUsed from "../pages/home/system/commonUsed.vue";
import test from "../components/Test.vue";
import NotNetwork from "../pages/NotNetwork.vue";
import attachment from '../components/attachment.vue';
import addAttachment from '../components/addAttachment.vue';

/**
 * 系统管理
 */
import food from '../pages/home/system/food.vue';
import agentCome from '../pages/home/system/agentCome.vue';
import agentComeDetail from '../pages/home/system/agentComeDetail.vue';
import warehouseLog from '../pages/home/system/warehouseLog.vue';
import comeMeterDetail from '../pages/home/system/comeMeterDetail.vue';
import lovMember from '../pages/home/system/lovMember.vue';
import foodCat from '../pages/home/system/foodCat.vue';
import profileManager from '../pages/home/system/profileManager.vue';
import userManager from '../pages/home/system/userManager.vue';
import userSales from '../pages/home/system/userSales.vue';
import orderManager from '../pages/home/system/orderManager.vue';
import orderDetail from '../pages/home/system/orderDetail.vue';
import print from '../pages/home/system/print.vue';
import printDelivery from '../pages/home/system/printDelivery.vue';
import reportManager from '../pages/home/system/reportManager.vue';
import warehouseManager from '../pages/home/system/warehouseManager.vue';


const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: 'index',
			isLogin: true,    // 添加该字段，表示进入这个路由是需要登录的
        }
        ,{
            path: '/index',
            component: index,
			isLogin: true,    // 添加该字段，表示进入这个路由是需要登录的
        }
        ,{
            path: '/shop',
            component: shop,
			isLogin: true,    // 添加该字段，表示进入这个路由是需要登录的
        }
        ,{
            path: '/orderConfirm',
            component: orderConfirm,
        }
        ,{
            path: '/orderPaying',
            component: orderPaying,
        }
        ,{
            path: '/addressList',
            component: addressList,
        }
        ,{
            path: '/order',
            component: order,
			isLogin: true,    // 添加该字段，表示进入这个路由是需要登录的
        }
        ,{
            path: '/person',
            component: person,
			isLogin: true,    // 添加该字段，表示进入这个路由是需要登录的
        }
        ,{
            path: '/backManager',
            component: backManager,
        }
		,{
		    path: '/commonUsed',
		    component: commonUsed,
		}
        ,{
            path: '/test',
            component: test,
        }
        ,{
            path: '/error',
            component: error,
        }
        ,{
            path: '/save',
            component: save,
        }
        ,{
            path: '/login',
            component: login,
        }
        ,{
            path: '/register',
            component: register,
        }
        ,{
            path: '/notnetwork',
            component: NotNetwork,
        }

        // 系统管理 start
        ,{
            path: '/crm/foodCat',
            component: foodCat,
        }
        ,{
            path: '/crm/food',
            component: food,
        }
        ,{
            path: '/crm/agentCome',
            component: agentCome,
        }
        ,{
            path: '/crm/agentComeDetail',
            component: agentComeDetail,
        }
        ,{
            path: '/crm/attachment',
            component: attachment,
        }
        ,{
            path: '/crm/addAttachment',
            component: addAttachment,
        }
        ,{
            path: '/crm/warehouseLog',
            component: warehouseLog,
        }
        ,{
            path: '/crm/comeMeterDetail',
            component: comeMeterDetail,
        }
        ,{
            path: '/crm/lovMember',
            component: lovMember,
        }
        ,{
            path: '/crm/profileManager',
            component: profileManager,
        }
        ,{
            path: '/crm/userSales',
            component: userSales,
        }
        ,{
            path: '/crm/userManager',
            component: userManager,
        }
        ,{
            path: '/crm/orderManager',
            component: orderManager,
        }
        ,{
            path: '/crm/orderDetail',
            component: orderDetail,
        }
        ,{
            path: '/crm/print',
            component: print,
			isLogin: false,    // 添加该字段，表示进入这个路由是需要登录的
        }
        ,{
            path: '/crm/printDelivery',
            component: printDelivery,
			isLogin: false,    // 添加该字段，表示进入这个路由是需要登录的
        }
        ,{
            path: '/crm/reportManager',
            component: reportManager,
        }
        ,{
            path: '/crm/warehouseManager',
            component: warehouseManager,
        }
        // 系统管理 end
    ]
});

export default router;