<template>
	<div class="content">
		<div>
			<van-sticky>
				<myNarBar class="title">
					<div slot="left" @click="goBack()">
						<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
					</div>
					<div slot="title">订单管理</div>
				</myNarBar>
			</van-sticky>
		</div>
		<div>
			<van-pull-refresh v-model="isLoading" @refresh="queryMyOrder">
				<van-tabs v-model="activeName" @click="queryMyOrder">
					<van-tab title="全部" name="全部">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span class="statusClass">{{item.STATUS}}</span>
								</div>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;"
								@click.stop="goDetail(item.ID, item.STATUS)">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image 
										:src="item.attUrl" 
										height="50" 
										width="50" >
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
					<van-tab title="待发货" name="待发货">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span class="statusClass">{{item.STATUS}}</span>
								</div>
									<span class="btnClass" @click="deliveryOrder(item, index)">发货</span>
									<span v-if="item.STATUS == '货到付款' || item.STATUS == '待发货'" class="btnClass" @click="toDelivery(item, index)">发货链接</span>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;"
								@click.stop="goDetail(item.ID, item.STATUS)">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image 
										:src="item.attUrl" 
										height="50" 
										width="50" >
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
					<van-tab title="已发货待付款" name="已发货待付款">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span class="statusClass">{{item.STATUS}}</span>
								</div>
									<span class="btnClass" @click="printOrder(item, index)">付款提醒</span>
									<span class="btnClass" @click="hasArrived(item, index, '已到账', 'hasArrived')">已到账</span>
									<span v-if="item.STATUS == '货到付款'" class="btnClass" @click="toInvoice(item, index)">开票链接</span>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;"
								@click.stop="goDetail(item.ID, item.STATUS)">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image 
										:src="item.attUrl" 
										height="50" 
										width="50" >
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
					<van-tab title="已发货" name="已发货">
						<div class="cardClass" v-for="(item,index) in orderList" :key="index">
							<div>
								<div style="text-align: left;padding-left: 10px;padding-top: 20px;">
									{{item.ORDER_NO}}
									<span class="statusClass">{{item.STATUS}}</span>
									<span v-if="item.REL_STATUS" class="statusClass">{{item.REL_STATUS}}</span>
									<span v-if="item.STATUS != '已送达' && item.REL_STATUS != '已送达'" class="btnClass" @click="hasArrived(item, index, '已送达', '')">已送达</span>
								</div>
							</div>
							<div>
								<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
									<span>{{formatDateHMS(item.DT_CREATED_AT)}}</span>
								</div>
							</div>
							<div style="text-align: left;margin: 20px;padding-left: 10px;">
								<van-image v-for="(item,index) in item.imgList" :key="index" :src="item.attUrl" height="80" width="80" style="padding-right: 20px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
								</van-image>						
							</div>
							<div>
								<div style="text-align: right;padding-top: 2px;padding-right: 10px;">
									<span>共{{item.COUNT}}件  实付：¥</span>
									<span style="font-size: 16px;font-weight: 600;">{{item.PRICE}}</span>
								</div>
							</div>
							<div v-if="item.PAY_STATUS == 'noPay'" style="text-align: left;padding-top: 2px;padding-left: 10px;"
								@click.stop="goDetail(item.ID, item.STATUS)">
								<label>实发明细</label>
								<van-icon class="icon" name="arrow" />
								<hr />
							</div>
							<div style="margin: 20px;"
									v-for="(item,index) in item.relList" :key="index" >
								<div style="padding-right: 10px;float: left;padding-left: 10px;">
									<van-image 
										:src="item.attUrl" 
										height="50" 
										width="50" >
									</van-image>
								</div>
								<div style="padding-right: 10px;text-align: left;padding-left: 100px;">
									<div>{{item.foodCatName}}</div>
									<div>单价：{{item.price}}</div>
									<div>数量：{{item.count}}</div>
									<div v-if="item.sales">折扣：{{item.sales}}</div>
									<div >添加人：{{item.createdByName}}</div>
									<div >添加时间：{{formatDate(item.createdAt)}}</div>
								</div>
							</div>
						</div>
					</van-tab>
				</van-tabs>
			</van-pull-refresh>
		</div>
		
		<div style="padding-top: 90px; z-index: 999999;">
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import {api} from "../../../common/request/api/index";
	import Vue from 'vue';
	import {
		Tab,Tabs,PullRefresh,Toast,Image
	} from 'vant'
	Vue.use(Tab).use(Tabs).use(PullRefresh).use(Toast).use(Image);
	import footerTabbar from '../../../components/footerTabbar.vue'
	import myNarBar from '../../../components/myNarBar.vue'
	export default {
		components: {
			footerTabbar,
			myNarBar
		},
		data() {
			return {
				isLoading: false,
				activeName: '全部',
				orderList: [],
			}
		},
		created() {
			this.queryMyOrder()
			
			api.queryWxTicket().then(res => {
				wx.config({
					debug: false,
					appId: res.appId,
					timestamp: res.timestamp,
					nonceStr: res.nonceStr,
					signature: res.signature,
					jsApiList: ["chooseWXPay","checkJsApi","updateAppMessageShareData"]
				});
				wx.ready(function(){
				});
				wx.error(function(){
				});
				Toast.clear();
			})
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			queryMyOrder(type){
				api.queryMyOrderBack({status: type}).then(res => {
					this.orderList = res
					this.isLoading = false
				})
			},
			formatDate(time) {
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			deliveryOrder(item, index){
				var status = '已发货';
				var type = 'hasPay';
				if(item.STATUS == '货到付款'){
					type = 'noPay';
				}
				console.log(item,index)
				if(item.STATUS == '货到付款' && item.relList == 0){
					Toast("货到付款单，明细不允许为空")
				}else{
					api.deliveryOrder({id: item.ID, status: '已发货', type: type}).then(res => {
						Toast(res)
						this.activeName = '全部',
						this.queryMyOrder()
					})
				}
			},
			printOrder(item, index){
				// this.$router.push({
				// 	path: '/crm/print',
				// 	query: {
				// 		orderId: item.ID,
				// 	}
				// });
				api.sengWxMsg({orderId: item.ID}).then(res => {
					Toast(res)
				}, err => {
					if (err.data.msg.indexOf(43101) != -1){
						Toast("已经提醒过，请勿重复提醒");	
					} else{
						tip(err.data.msg||"稍后重试");
					}
					return Promise.reject(err);
				})
			},
			hasArrived(item, index, status, type){
				api.updateOrderStatus({id: item.ID, status: status, type: type}).then(res => {
					Toast(res)
					this.activeName = '全部',
					this.queryMyOrder()
				})
			},
			toDelivery(item, index){
				// window.open('https://szshcy.cn/#/crm/print?orderId='+item.ID)
				const link = "https://szshcy.cn/#/crm/printDelivery?orderId="+item.ID;
				 
				// 创建临时 <textarea> 元素，将链接写入其中
				const textArea = document.createElement("textarea");
				textArea.value = link;
				document.body.appendChild(textArea);
				 
				// 选中并复制链接
				textArea.select();
				document.execCommand("copy");
				 
				// 将 <textarea> 元素删除
				document.body.removeChild(textArea);
				Toast("复制成功，请在网页上打开")
			},
			toInvoice(item, index){
				// window.open('https://szshcy.cn/#/crm/print?orderId='+item.ID)
				const link = "https://szshcy.cn/#/crm/print?orderId="+item.ID;
				 
				// 创建临时 <textarea> 元素，将链接写入其中
				const textArea = document.createElement("textarea");
				textArea.value = link;
				document.body.appendChild(textArea);
				 
				// 选中并复制链接
				textArea.select();
				document.execCommand("copy");
				 
				// 将 <textarea> 元素删除
				document.body.removeChild(textArea);
				Toast("复制成功，请在网页上打开")
			},
			goDetail(id, status){
				if(status == '货到付款'){
					this.$router.push({
						path: '/crm/orderDetail',
						query: {
							orderId: id,
						}
					});
				}
			}
		},
		mounted() {
		},
	};
</script>

<style scoped>

	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    font-size: 14px;
	    background-color: #fff;
		margin: 10px;
		border-radius: 5px;
	}
	
	.fileList span {
		font-size: 14px;
	}
	
	.shopCardClass {
		background-color: white;
		border: 1px;
		z-index: 999999; 
		position: fixed;
		bottom: 0; 
		width: 100%; 
		height: 60px; 
	}
	
	.buyClass {
		float: right; 
		padding: 3px 10px; 
		background-color: red;
		margin: 0px 10px;
		border-radius: 50px;
		font-size: 10px;
		color: white;
	}
	
	.countClass {
		font-size: 20px;
		padding-right: 10px;
	}
	
	.allDiv {
		width: 100%;
		height: 100px;
		display: inline-block;
	}
	
	.leftDiv {
		padding-top: 30px;
		width: 20%;
		height: 200px;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.rightDiv {
		padding-top: 30px;
		margin-left: 20%;
		width: 80%;
		height: 200px;
		text-align: left;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.titleClass {
		text-align: left; 
		padding-left: 10px; 
		font-size: 20px;
	}
	
	.addressClass {
		font-size: 14px;
	}
	
	.statusClass {
		font-size: 14px;
		padding: 0px 5px; 
		margin-right: 10px;
		border-radius: 5px;
		background-color: aquamarine;
	}
	
	.btnClass {
		float: right;
		font-size: 14px;
		padding: 0px 5px; 
		margin-right: 10px;
		border-radius: 5px;
		color: white;
		background-color: royalblue;
	}
</style>
