<template>
	<div class="content">
		<div style="overflow: hidden">
			<van-sticky>
				<myNarBar class="title">
					<div slot="left" @click="goBack()">
						<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
					</div>
					<div slot="title">确认订单</div>
				</myNarBar>
			</van-sticky>
		</div>
		<div class="cardClass">
			<div class="titleClass">
				配送地址
				<van-button style="float: right; padding: 0px 10px;margin: 0px 10px;" @click="toAddAddress" size="mini">新增</van-button>
				<van-divider />
				<div class="addressClass">
					{{address.address}}
				</div>
				<div class="addressClass">
					{{address.name}}{{address.tel}}
				</div>
			</div>
		</div>
		<div class="cardClass">
			<van-list :finished="finished" finished-text="没有更多了" :offset="10">
				<div>
					<div class="titleClass">B</div>
					<div v-for="(file, index) in hasChooseFood" :key="index" :index="index">
						<van-swipe-cell>
							<div class="allDiv">
								<van-divider />
								<div class="leftDiv">
									<div>
										<van-image
											width="50"
											height="50"
											:src="file.attUrl"
										/>
									</div>
								</div>
								<div class="rightDiv">
									<div style="float: right; padding-right: 20px;">¥{{file.price}}</div>
									<div>{{file.relName}}</div>
									<div>
										×{{file.count}}
									</div>
								</div>
							</div>
						</van-swipe-cell>
					</div>
				</div>
			</van-list>
		</div>
		<div class="cardClass">
			<div class="titleClass">订单备注</div>
			<van-field
			  v-model="message"
			  rows="2"
			  autosize
			  type="textarea"
			  maxlength="50"
			  placeholder="请填写备注信息"
			  show-word-limit
			/>
		</div>
		
		<div class="shopCardClass" v-if="shopCard.totalPrice > 0">
			<div @click="showShopCardDetail = true">
				<div>
					<span style="float: left; padding: 20px; 0px 0px 10px;">实付金额¥{{shopCard.totalPrice}}</span>
				</div>
			</div>
			<div>
				<span v-if="showPayBtn" class="buyClass" @click="toPay('pay')">微信支付</span>
				<span v-if="showPayBtn" class="buyClass1" @click="toPay('noPay')">货到付款</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {api} from "../../common/request/api/index";
	import Vue from 'vue';
	import {
		Cascader,ImagePreview,DropdownMenu,DropdownItem,PullRefresh,Toast,SwipeCell,Search,Sidebar,SidebarItem,Card,Popup,Divider 
	} from 'vant'
	Vue.use(Cascader).use(ImagePreview).use(DropdownMenu).use(DropdownItem).use(Popup).use(Divider)
	.use(PullRefresh).use(Toast).use(SwipeCell).use(Search).use(Sidebar).use(SidebarItem).use(Card);
	import footerTabbar from '../../components/footerTabbar.vue'
	import myNarBar from '../../components/myNarBar.vue'
	export default {
		components: {
			footerTabbar,
			myNarBar
		},
		data() {
			return {
				show: false,
				isLoading: false,
				loading: false,
				finished: false,
				refreshing: false,
				
				shopCard: [],
				hasChooseFood: [],
				showShopCardDetail: false,
				message: '',
				
				address: {},
				
				showPayBtn: true,
			}
		},
		created() {
			api.queryAddress({isDefault: "true"}).then(res => {
				this.address = res[0];
			})
			
			
			api.queryPayDate().then(res => {
				this.showPayBtn = res == "Y" ? false : true;
			})
			
			this.hasChooseFood = JSON.parse(localStorage.getItem("hasChooseFood"));
			this.calcShopCard();
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			formatDate(time) {
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			calcShopCard(){
				let price = 0;
				this.hasChooseFood.forEach(item => {
					price += item.count * item.price
				})
				this.shopCard.totalPrice = price.toFixed(2);
			},
			toAddAddress(){
				this.$router.push({
					path: '/addressList'
				});
			},
			toPay(type){
				this.hasChooseFood.forEach(item => {
						item.createdAt = this.formatDateHMS(item.createdAt)
						item.updatedAt = this.formatDateHMS(item.updatedAt)
				})
				var status = "待支付";
				var payStatus = "pay";
				if(type == "noPay"){
					status = "货到付款";
					payStatus = "noPay";
				}
				api.insertUpdateOrder(status, payStatus, this.message, this.address.address, this.address.name, this.address.tel, JSON.stringify(this.hasChooseFood)).then(res => {
					localStorage.removeItem("hasChooseFood")
					this.$router.push({
						path: '/orderPaying',
						query: {
							orderId: res.id
						}
					});
				})
			}
		},
		mounted() {
			this.calcShopCard()
		},
	};
</script>

<style scoped>

	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    font-size: 14px;
	    background-color: #fff;
		margin: 10px;
		border-radius: 5px;
	}
	
	.fileList span {
		font-size: 14px;
	}
	
	.shopCardClass {
		background-color: white;
		border: 1px;
		z-index: 999999; 
		position: fixed;
		bottom: 0; 
		width: 100%; 
		height: 60px; 
	}
	
	.buyClass {
		float: right; 
		padding: 10px 10px; 
		background-color: red;
		margin: 0px 10px;
		border-radius: 50px;
		font-size: 10px;
		color: white;
	}
	
	.countClass {
		font-size: 20px;
		padding-right: 10px;
	}
	
	.buyClass1 {
		float: right; 
		padding: 10px 10px; 
		background-color: royalblue;
		margin: 0px 10px;
		border-radius: 50px;
		font-size: 10px;
		color: white;
	}
	
	.allDiv {
		width: 100%;
		height: 100px;
		display: inline-block;
	}
	
	.leftDiv {
		padding-top: 30px;
		width: 20%;
		height: 200px;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.rightDiv {
		padding-top: 30px;
		margin-left: 20%;
		width: 80%;
		height: 200px;
		text-align: left;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.titleClass {
		text-align: left; 
		padding-left: 10px; 
		font-size: 20px;
	}
	
	.addressClass {
		font-size: 14px;
	}
</style>
