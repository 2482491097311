<template>
	<div>
		<div>
			<van-sticky>
				<myNarBar class="title">
					<div slot="title">首页</div>
				</myNarBar>
			</van-sticky>
		</div>
		<div style="padding-top: 5px;">
			<div class="contentClass">烧皇餐饮是一家以直营、合作加盟，以及团购定制配送为主的烧腊食品企业。是深圳市烧腊行业的新标杆。</div>
			<div>
				<van-image v-for="(item,index) in images" :src="item" :key="index" height="250" :width="windowWidth"></van-image>
			</div>
		</div>
		<div style="padding-top: 90px;">
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import {api} from "../../common/request/api/index";
	import b1 from '../../assets/img/b1.jpg';
	import b2 from '../../assets/img/b2.jpg';
	import b3 from '../../assets/img/b3.jpg';
	import b4 from '../../assets/img/b4.jpg';
	import b5 from '../../assets/img/b5.jpg';
	import Vue from 'vue';
	import {
		Toast,SwipeCell,Search,
		Swipe,
		SwipeItem,
		ImagePreview
	} from 'vant'
	Vue.use(Toast).use(SwipeCell).use(Search).use(Swipe).use(SwipeItem).use(ImagePreview);
	import footerTabbar from '../../components/footerTabbar.vue'
	import myNarBar from '../../components/myNarBar.vue'
	export default {
		components: {
			footerTabbar,
			myNarBar,
		},
		data() {
			return {
				searchKey: '',
				pcProgram: navigator.userAgent.toUpperCase().match("MINIPROGRAM") != null && !this.IsMobile(),
				images: [
					b3,
					b1,
					b2,
					b5,
					// b4,
				],
				windowHeight: window.innerHeight/2.5,
				windowWidth: window.innerWidth,
			}
		},
		created() {
			this.querySideBarMenu();
		},
		methods: {
			isWeiXin(){
				var ua = window.navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == 'micromessenger'){
					return true;
				}else{
					return false;
				}
			},
			querySideBarMenu(){
				api.queryFoodCat(this.queryData)
			},
			imagePreview(index){
				ImagePreview({
					images: this.images,
					startPosition: index,
					closeOnPopstate: true,
					closeable: true,
				})
			},
		},
	};
</script>

<style scoped>
	.contentClass {
		text-align: left; 
		padding: 10px; 
		background-color: red; 
		color: white;
	}
</style>
