import router from '@/router/index';
import axios from '../http';
import {
	tip
} from "../../modal";
import qs from 'qs';

const base = {    
    dev: '/axios',    
	stg: 'https://10.2.207.70:8001',
    prod: 'https://szshcy.cn:8000/b-cloud',
}
const url = base.prod;

const api = {
	networkTest() {
		return axios.get(`${url}/testNetwork.loginFree`);
	}, 
	login(username, password) {
		return axios.post(`${url}/login.do`, qs.stringify({
			username,
			password
		})).then(data => {
			localStorage.setItem("userObject", JSON.stringify(data));
			localStorage.setItem("token", data.token);
			return Promise.resolve(data);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	logout() {
		return axios.post(`${url}/logout.do`).then(data => {
			tip(data)
			localStorage.setItem("userObject", "");
			localStorage.setItem("token", "");
			router.replace({
				path: '/login',
				query: {
					redirect: router.currentRoute.fullPath
				}
			});
		});
	},
	queryWxTicket(params) {
		return axios.get(`${url}/wx/queryWxTicket.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryWxPay(params) {
		return axios.get(`${url}/wx/queryWxPay.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	updatePassword(params) {
		return axios.get(`${url}/updatePassword.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryFood(params) {
		return axios.get(`${url}/queryFood.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateFood(params) {
		var formDataArr = new FormData();
		for(let key in params.food){
			formDataArr.append(key,params.food[key]);
		}
		//循环将附件加到参数里面
		for(var i = 0; i < params.fileList.length; i++) {
			formDataArr.append("fileList", params.fileList[i].file);
		}
		return axios.post(`${url}/insertUpdateFood.do`, formDataArr).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteFood(params) {
		return axios.get(`${url}/deleteFood.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryFoodCat(params) {
		return axios.get(`${url}/queryFoodCat.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateFoodCat(params) {
		return axios.get(`${url}/insertUpdateFoodCat.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteFoodCat(params) {
		return axios.get(`${url}/deleteFoodCat.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryAddress(params) {
		return axios.get(`${url}/queryAddress.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateAddress(params) {
		return axios.get(`${url}/insertUpdateAddress.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteAddress(params) {
		return axios.get(`${url}/deleteAddress.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryComemeter(params) {
		return axios.get(`${url}/queryComemeter.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateComemeterReport(params) {
		return axios.get(`${url}/insertUpdateComemeterReport.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateComemeterReportReturn(params) {
		return axios.get(`${url}/insertUpdateComemeterReportReturn.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	handelIn(params) {
		return axios.get(`${url}/handelIn.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	handelReturn(params) {
		return axios.get(`${url}/handelReturn.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	changeAmount(params) {
		return axios.get(`${url}/changeAmount.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryComemeterDetailAgent(params) {
		return axios.get(`${url}/queryComemeterDetailAgent.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryComemeterAgent(params) {
		return axios.get(`${url}/queryComemeterAgent.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryComemeterHeader(params) {
		return axios.get(`${url}/queryComemeterHeader.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryComemeterDetail(params) {
		return axios.get(`${url}/queryComemeterDetail.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryComemeterHeaderAgent(params) {
		return axios.get(`${url}/queryComemeterHeaderAgent.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteComemeterHeader(params) {
		return axios.get(`${url}/deleteComemeterHeader.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateComemeterAgent(params) {
		return axios.get(`${url}/insertUpdateComemeterAgent.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryComemeterDetailAgent(params) {
		return axios.get(`${url}/queryComemeterDetailAgent.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertComemeterHeader(params) {
		return axios.get(`${url}/insertComemeterHeader.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	updateComemeterHeader(params) {
		return axios.get(`${url}/updateComemeterHeader.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateComemeter(params) {
		return axios.get(`${url}/insertUpdateComemeter.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteComemeter(params) {
		return axios.get(`${url}/deleteComemeter.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryLovMember(params) {
		return axios.get(`${url}/queryLovMember.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateLovMember(params) {
		return axios.get(`${url}/insertUpdateLovMember.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteLovMember(params) {
		return axios.get(`${url}/deleteLovMember.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryOrder(params) {
		return axios.get(`${url}/queryOrder.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	updateOrderStatus(params) {
		return axios.get(`${url}/updateOrderStatus.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryOrderDetail(params) {
		return axios.get(`${url}/queryOrderDetail.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deliveryOrder(params) {
		return axios.get(`${url}/deliveryOrder.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryMyOrder(params) {
		return axios.get(`${url}/queryMyOrder.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryMyOrderBack(params) {
		return axios.get(`${url}/queryMyOrderBack.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryMyOrderBackFree(params) {
		return axios.get(`${url}/queryMyOrderBackFree.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateOrder(status, payStatus, remark, reciveAddress, reciveName, reciveTel, details) {
		console.log(status, payStatus, remark, reciveAddress, reciveName, reciveTel, details)
		return axios.post(`${url}/insertUpdateOrder.do`, qs.stringify({
			status, payStatus, remark, reciveAddress, reciveName, reciveTel, details
		})).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertOrderDetail(id, details) {
		return axios.post(`${url}/insertOrderDetail.do`, qs.stringify({
			id, details
		})).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteOrder(params) {
		return axios.get(`${url}/deleteOrder.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryEmployee(params) {
		return axios.get(`${url}/queryEmployee.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryEmployeeSales(params) {
		return axios.get(`${url}/queryEmployeeSales.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateEmployeeSales(params) {
		return axios.get(`${url}/insertUpdateEmployeeSales.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryEmployeeList(params) {
		return axios.get(`${url}/queryEmployeeList.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateEmployee(params) {
		return axios.get(`${url}/insertUpdateEmployee.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateEmployeeOut (params) {
		var formDataArr = new FormData();
		for(let key in params.employee){
			formDataArr.append(key,params.employee[key]);
		}
		//循环将附件加到参数里面
		for(var i = 0; i < params.fileList.length; i++) {
			formDataArr.append("fileList", params.fileList[i].file);
		}
		return axios.post(`${url}/insertUpdateEmployeeOut.loginFree`, formDataArr).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateEmployeeDate(params) {
		return axios.get(`${url}/insertUpdateEmployeeDate.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryPayDate(params) {
		return axios.get(`${url}/queryPayDate.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateEmployeeFree(params) {
		return axios.get(`${url}/insertUpdateEmployee.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryEmployee(params) {
		return axios.get(`${url}/queryEmployee.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryPermission(params) {
		return axios.get(`${url}/queryPermission.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdatePermission(userId, permissionList) {
		return axios.post(`${url}/insertUpdatePermission.do`, qs.stringify({
			userId,permissionList
		})).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertUpdateEmployeeFree(params) {
		return axios.get(`${url}/insertUpdateEmployee.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryFile(params) {
		return axios.get(`${url}/queryFile.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	querySideBarMenu(params) {
		return axios.get(`${url}/querySideBarMenu.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertFilePermission(fileId, checkedIds, permissionIds) {
		return axios.post(`${url}/insertFilePermission.do`, qs.stringify({
			fileId,
			checkedIds,
			permissionIds
		})).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertRolePermission(roleId, checkedIds, permissionIds) {
		return axios.post(`${url}/insertRolePermission.do`, qs.stringify({
			roleId,
			checkedIds,
			permissionIds
		})).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryFileSystem(params) {
		return axios.get(`${url}/queryFileSystem.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryFileRolePermission(params) {
		return axios.get(`${url}/queryFileRolePermission.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	insertFileRolePermission(str, roleId) {
		return axios.post(`${url}/insertFileRolePermission.do`, qs.stringify({
			str,
			roleId
		})).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	validFile(params) {
		return axios.get(`${url}/validFile.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	deleteFile(params) {
		return axios.get(`${url}/deleteFile.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	queryWarehouseLog(params) {
		return axios.get(`${url}/queryWarehouseLog.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	sengWxMsg(params) {
		return axios.get(`${url}/wx/sengWxMsg.loginFree`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		});
	},
	comemeterHeaderHasAccount(params) {
		return axios.get(`${url}/comemeterHeaderHasAccount.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	updateComemeterHeaderStatusAgent(params) {
		return axios.get(`${url}/updateComemeterHeaderStatusAgent.do`, {
			params: params
		}).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
	uploadFile(params) {
		var formDataArr = new FormData();
		for(let key in params){
			formDataArr.append(key,params[key]);
		}
		
		//循环将附件加到参数里面
		for(var i = 0; i < params.fileList.length; i++) {
			formDataArr.append("fileList", params.fileList[i].file);
		}
		return axios.post(`${url}/uploadFile.do`, formDataArr).then(res => {
			return Promise.resolve(res);
		}, err => {
			if (err) tip(err.data.msg);
			return Promise.reject(err);
		});
	},
}
export {
	api
}