<template>
	<div>
		<div>
			<van-sticky>
				<myNarBar class="title">
					<div slot="left" @click="goBack()">
						<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
					</div>
					<div slot="title">地址列表</div>
				</myNarBar>
			</van-sticky>
		</div>
		
		<div>
			<van-pull-refresh v-model="isLoading" @refresh="queryAddress">
				<van-address-list
					v-model="chosenAddressId"
					:list="list"
					default-tag-text="默认"
					@add="onAdd"
					@edit="onEdit"
					@select="onSelect"
				/>
			</van-pull-refresh>
		</div>
		
		<van-dialog v-model="showAddress" show-cancel-button @confirm="onSubmit" style="width: 100%; height: 45%;">
			<van-form>
				<van-field
					v-model="address.name"
					name="name"
					label="名称"
					placeholder="名称"
				/>
				<van-field
					v-model="address.tel"
					name="phone"
					label="电话"
					placeholder="电话"
				/>
				<van-field
					v-model="address.address"
					name="address"
					label="地址信息"
					placeholder="地址信息"
					type="textarea"
					rows="2"
					show-word-limit
					maxlength="50"
				/>
				<van-field name="switch" label="是否默认">
					<template #input>
						<van-switch v-model="address.isDefault" size="20" />
					</template>
				</van-field>
		  </van-form>
		</van-dialog>
	</div>
</template>

<script>
	import {api} from "../../common/request/api/index";
	import Vue from 'vue';
	import {
		AddressList,Toast,PullRefresh,Switch
	} from 'vant'
	Vue.use(AddressList).use(Toast).use(PullRefresh).use(Switch)
	import footerTabbar from '../../components/footerTabbar.vue'
	import myNarBar from '../../components/myNarBar.vue'
	export default {
		components: {
			footerTabbar,
			myNarBar
		},
		data() {
			return {
				chosenAddressId: 'cdeb9801aee37860d644702b33a6b93e',
				list: [
			    ],
				showAddress: false,
				address: {
					id: '',
					name: '',
					tel: '',
					address: '',
					isDefault: false,
				},
				isLoading: false
			}
		},
		created() {
			this.queryAddress();
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			onAdd() {
				this.showAddress = !this.showAddress;
			},
			onEdit(item, index) {
				this.showAddress = !this.showAddress;
				this.address = item;
			},
			onSelect(item, index){
				console.log(item, index)
				this.address = item;
				item.isDefault = "true"
				this.onSubmit();
			},
			queryAddress(){
				api.queryAddress().then(res => {
					res.forEach(item => {
						item.isDefault = item.isDefault == "true"?true:false;
					})
					console.log(res)
					this.list = res
					this.chosenAddressId = res[0].id
					this.isLoading = false
				})
			},
			onSubmit(){
				this.address.createdAt = this.formatDateHMS(this.address.createdAt)
				this.address.updatedAt = this.formatDateHMS(this.address.updatedAt)
				api.insertUpdateAddress(this.address).then(res => {
					Toast("操作成功")
					this.queryAddress();
				})
			},
		},
	};
</script>

<style scoped>

	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    font-size: 14px;
	    background-color: #fff;
		margin: 10px;
		border-radius: 5px;
	}
	
	.fileList span {
		font-size: 14px;
	}
	
	.shopCardClass {
		background-color: white;
		border: 1px;
		z-index: 999999; 
		position: fixed;
		bottom: 0; 
		width: 100%; 
		height: 60px; 
	}
	
	.buyClass {
		float: right; 
		padding: 10px 20px; 
		background-color: red;
		margin: 0px 20px;
		border-radius: 50px;
		font-size: 20px;
		color: white;
	}
	
	.countClass {
		font-size: 20px;
		padding-right: 10px;
	}
	
	.allDiv {
		width: 100%;
		height: 100px;
	}
	
	.leftDiv {
		width: 20%;
	}
	
	.rightDiv {
		padding: 0px 50px 0px 80px;
		width: 80%;
		text-align: left;
	}
	
	.titleClass {
		text-align: left; 
		padding-left: 10px; 
		font-size: 20px;
	}
</style>
