<template>
	<div>
		<div>
			<van-sticky>
				<myNarBar class="narBarTitle">
					<div slot="left" @click="goBack()">
						<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
					</div>
					<div slot="title">报表管理</div>
				</myNarBar>
			</van-sticky>
		</div>
		<div class="echarts_box">
			<div class="">
				<el-button type="primary" @click="onSubmit">查询</el-button>
				<el-button type="primary" @click="onDeep">{{colorName}}</el-button>
				<el-button type="primary" v-print="'#printContent'">打印</el-button>
			</div>
			<div id="printContent">
				<div>销售报表</div>
				<div id="chart" :style="{width:windowWidth+'px', height:'400'+'px'}"></div>
				<div>库存报表</div>
				<div id="chart1" :style="{width:windowWidth+'px', height:'400'+'px'}"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import myNarBar from '../../../components/myNarBar.vue'
	import echarts from 'echarts';
	import Vue from 'vue';
	import {
		Sticky,
		Toast,
	} from 'vant';
	import {
		tip
	} from '../../../common/modal';
	Vue.use(Sticky).use(Toast);
	var myChart, option, myChart1, option1;
	export default {
		name: "reportManager",
		components: {myNarBar},
		data() {
			return {
				num: 55,
				total: 1100,
				thisTotal: 1100,
				xAxis: [],
				data1: [],
				data2: [],
				data3: [],

				total: {
					"comeTotal": 517362,
					"errorTotal": 1803,
					"checkTotal": 5364,
				},
				data: [{
						"xAxis": '2023-11-01',
						"checkNum": 4208,
						"errorNum": 1058,
						"percent": 25.14,
					},
					{
						"xAxis": '2023-11-02',
						"checkNum": 285,
						"errorNum": 229,
						"percent": 80.35
					},
					{
						"xAxis": '2023-11-03',
						"checkNum": 175,
						"errorNum": 175,
						"percent": 100
					},
					{
						"xAxis": '2023-11-04',
						"checkNum": 205,
						"errorNum": 129,
						"percent": 62.93
					},
					{
						"xAxis": '2023-11-05',
						"checkNum": 110,
						"errorNum": 101,
						"percent": 91.82
					},
					{
						"xAxis": '2023-11-06',
						"checkNum": 125,
						"errorNum": 30,
						"percent": 24
					},
					{
						"xAxis": '2023-11-07',
						"checkNum": 100,
						"errorNum": 22,
						"percent": 22
					},
					{
						"xAxis": '2023-11-08',
						"checkNum": 63,
						"errorNum": 20,
						"percent": 31.75
					},
					{
						"xAxis": '2023-11-09',
						"checkNum": 15,
						"errorNum": 10,
						"percent": 66.67
					},
					{
						"xAxis": '2023-11-10',
						"checkNum": 10,
						"errorNum": 10,
						"percent": 100
					},
					{
						"xAxis": '2023-11-11',
						"checkNum": 50,
						"errorNum": 9,
						"percent": 18
					},
					{
						"xAxis": '2023-11-12',
						"checkNum": 5,
						"errorNum": 5,
						"percent": 100
					},
					{
						"xAxis": '2023-11-13',
						"checkNum": 13,
						"errorNum": 5,
						"percent": 38.46
					},
				],
				deep: false,
				colorName: '',
				
				windowWidth: window.innerWidth,
			};
		},
		mounted() {
			let that = this
			let errorSum = 0;
			this.data.forEach(item => {
				that.xAxis.push(item.xAxis)
				that.data1.push(item.errorNum)
				errorSum = errorSum + item.errorNum;
				var pe = (errorSum / that.total.errorTotal * 100).toFixed(2)
				that.data2.push(pe)
				that.data3.push(item.checkNum)
			})
			this.initData();
			this.colorName = this.deep ? "亮色" : "深色"
		},
		methods: {
			onSubmit() {
				this.initData();
			},
			goBack() {
				this.$router.go(-1)
			},
			onDeep() {
				this.deep = !this.deep;
				this.colorName = this.deep ? "亮色" : "深色"
				myChart.dispose();
				myChart1.dispose();
				if (this.deep) {
					myChart = echarts.init(document.getElementById('chart'), 'dark');
					myChart1 = echarts.init(document.getElementById('chart1'), 'dark');
				} else {
					myChart = echarts.init(document.getElementById('chart'), 'light');
					myChart1 = echarts.init(document.getElementById('chart1'), 'light');
				}
				myChart.setOption(option);
				myChart1.setOption(option1);
			},
			setLigth() {

			},
			setDark() {

			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			initData() {
				let chartDom = document.getElementById('chart');
				let chartDom1 = document.getElementById('chart1');
				myChart = echarts.init(chartDom)
				myChart1 = echarts.init(chartDom1)

				option = {
					// backgroundColor:'rgba(214, 214, 214)', // rgba设置透明度0.1
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#ff5500'
							}
						},
						formatter(param) {
							return "占比： " + param[1].value + "%" + "<br/>" + "销售数量： " +
								param[0].value
						}
					},
					title: {
						text: '销售报表' + this.formatDateHMS(new Date()),
						x: 'left',
						show: false,
					},
					toolbox: {
						feature: {
							dataView: {
								show: true,
								readOnly: false
							},
							magicType: {
								show: true,
								type: ['line', 'bar']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					color: ['#00ff7f', '#0E5FFF', '#ff0000'],
					legend: {
						data: ['不良小类简称', '抽样数量', '抽样不良品数量', '占比']
					},
					grid: { //直角坐标系内绘图网格
						show: false, //是否显示直角坐标系网格。[ default: false ]
						left: "20%", //grid 组件离容器左侧的距离。
						// right: "20%",
						// borderColor:"#c45455",//网格的边框颜色
						bottom: "30%" //
					},
					xAxis: [{
						type: 'category',
						name: '日期',
						interval: 0,
						nameLocation: 'start', //左：start 右：end
						data: this.xAxis,
						axisPointer: {
							type: 'line'
						},
						axisLabel: {
							color: '#333',
							rotate: 45,
							textStyle: {
								color: '#7e7e7e',
								fontSize: '11'
							},
							formatter: function(value) {
								if (value.length > 10) {
									return value.substring(0, 10) + '...';
								} else {
									return value;
								}
							}
						}
					}],
					yAxis: [{
							type: 'value',
							name: '销售数量:' + this.num,
							min: 0,
							max: 1200,
							axisTick: {
								show: false // 不显示坐标轴刻度线
							},
							splitLine: {
								show: false, // 网格线是否显示
							},
							interval: 200,
							axisLabel: {
								textStyle: {
									color: '#7e7e7e',
									fontSize: '10'
								},
								formatter: '{value}'
							}
						},
						{
							type: 'value',
							name: '占比',
							min: 0,
							max: 100,
							axisTick: {
								show: false // 不显示坐标轴刻度线
							},
							splitLine: {
								show: false, // 网格线是否显示
							},
							interval: 20,
							axisLabel: {
								textStyle: {
									color: '#7e7e7e',
									fontSize: '10'
								},
								formatter: '{value}%'
							}
						}
					],
					series: [{
							name: '销售数量',
							type: 'bar',
							show: false,
							barWidth: 10, // 宽度
							data: this.data3
						},
						{
							name: '占比',
							type: 'line',
							smooth: true, // 平滑折线
							yAxisIndex: 1,
							data: this.data2
						}
					]
				};
				option1 = {
					// backgroundColor:'rgba(214, 214, 214)', // rgba设置透明度0.1
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#ff5500'
							}
						},
						formatter(param) {
							return "占比： " + param[1].value + "%" + "<br/>" + "销售数量： " +
								param[0].value
						}
					},
					title: {
						text: '库存报表' + this.formatDateHMS(new Date()),
						x: 'left',
						show: false,
					},
					toolbox: {
						feature: {
							dataView: {
								show: true,
								readOnly: false
							},
							magicType: {
								show: true,
								type: ['line', 'bar']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					color: ['#00ff7f', '#0E5FFF', '#ff0000'],
					legend: {
						data: ['不良小类简称', '抽样数量', '抽样不良品数量', '占比']
					},
					grid: { //直角坐标系内绘图网格
						show: false, //是否显示直角坐标系网格。[ default: false ]
						left: "20%", //grid 组件离容器左侧的距离。
						// right: "20%",
						// borderColor:"#c45455",//网格的边框颜色
						bottom: "30%" //
					},
					xAxis: [{
						type: 'category',
						name: '日期',
						interval: 0,
						nameLocation: 'start', //左：start 右：end
						data: this.xAxis,
						axisPointer: {
							type: 'line'
						},
						axisLabel: {
							color: '#333',
							rotate: 45,
							textStyle: {
								color: '#7e7e7e',
								fontSize: '11'
							},
							formatter: function(value) {
								if (value.length > 10) {
									return value.substring(0, 10) + '...';
								} else {
									return value;
								}
							}
						}
					}],
					yAxis: [{
							type: 'value',
							name: '库存数量:' + this.num,
							min: 0,
							max: 1200,
							axisTick: {
								show: false // 不显示坐标轴刻度线
							},
							splitLine: {
								show: false, // 网格线是否显示
							},
							interval: 200,
							axisLabel: {
								textStyle: {
									color: '#7e7e7e',
									fontSize: '10'
								},
								formatter: '{value}'
							}
						},
						{
							type: 'value',
							name: '占比',
							min: 0,
							max: 100,
							axisTick: {
								show: false // 不显示坐标轴刻度线
							},
							splitLine: {
								show: false, // 网格线是否显示
							},
							interval: 20,
							axisLabel: {
								textStyle: {
									color: '#7e7e7e',
									fontSize: '10'
								},
								formatter: '{value}%'
							}
						}
					],
					series: [{
							name: '库存数量',
							type: 'bar',
							show: false,
							barWidth: 10, // 宽度
							data: this.data3
						},
						{
							name: '占比',
							type: 'line',
							smooth: true, // 平滑折线
							yAxisIndex: 1,
							data: this.data2
						}
					]
				};
				option && myChart.setOption(option);
				option && myChart1.setOption(option);
				myChart.on('click', function(event) {
					console.log(event)
				})
				myChart1.on('click', function(event) {
					console.log(event)
				})
			},
		}
	};
</script>

<style scoped>
	/* 请根据实际需求修改父元素尺寸，组件自动识别宽高 */
	.echarts_box {
		width: auto;
		height: 300px;
	}
</style>