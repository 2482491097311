<template>
	<div>
		<div class="content">
			<table style="width: 50%;">
				<tr name="rBody" style="height: 8.5cm;vertical-align: top; margin-left: 20px; ">
					<td>
						<div style="text-align: center; font-size: 14px; font-weight: 600; padding-top: 0.5cm;">烧皇餐饮店</div>
						<div>
							<span style="font-size: 4px; float: left; padding-left: 10px;">
								登记号：
							</span>
							<span style="float: right;">
								<van-image v-for="(item,index) in images" :src="item" :key="index" height="70" ></van-image>
							</span>
							<span style="width: 50%; font-size: 4px; float: right; padding-left: 10px;">
								白联留底/红客客户/黄联仓库
							</span>
						</div>
						<br />
						<div style="font-size: 4px; padding-left: 10px; width: 70%;">
							<span style="float: left;">
								单据编号：{{header.ORDER_NO}}
							</span>
							<span style="float: right; padding-right: 100px;">
								制单日期：{{formatDate(header.DT_CREATED_AT)}}
							</span>
						</div>
						<br />
						<div style="font-size: 4px; padding-left: 10px; width: 70%;">
							<span style="float: left; ">
								客户名称：{{header.SHOP_NAME}}
							</span>
							<span style="float: right;padding-left: 10px;">
								客户地址：{{header.ADDRESS}}
							</span>
							<span style="float: right;">
								客户电话：{{header.PHONE}}
							</span>
						</div>
						<table style="width:19cm;font-size: 6px; border-bottom :1px #333 solid;margin:1%;" cellspacing=0>
							<tr style="line-height: 16px;font-weight: bold;">
								<td class="center" width="30px" style="border-right :1px #333 solid;border-top :1px #333 solid;border-bottom :1px #333 solid;border-left :1px #333 solid;">序号</td>
								<td class="center" width="60px" style="border-right :1px #333 solid;border-top :1px #333 solid;border-bottom :1px #333 solid;">货品名称</td>
								<td class="center" width="30px" style="border-right :1px #333 solid;border-top :1px #333 solid;border-bottom :1px #333 solid;">单位</td>
								<td class="center" width="45px" style="border-right :1px #333 solid;border-top :1px #333 solid;border-bottom :1px #333 solid;">数量</td>
								<td class="center" width="45px" style="border-right :1px #333 solid;border-top :1px #333 solid;border-bottom :1px #333 solid;">单价</td>
								<td class="center" width="60px" style="border-right :1px #333 solid;border-top :1px #333 solid;border-bottom :1px #333 solid;">金额</td>
								<td class="center" width="45px" style="border-right :1px #333 solid;border-top :1px #333 solid;border-bottom :1px #333 solid;">备注</td>
							</tr>
							<tr style="line-height: 12px;" v-for="(item,index) in detailList" :key="index">
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;border-left :1px #333 solid;">{{++index}}</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;">{{item.relName}}</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;">{{item.unit}}</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;">{{item.count}}</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;">{{item.price}}</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;" v-if="item.sales != null">{{(item.count*item.price*item.sales/10).toFixed(2)}}</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;" v-if="item.sales == null">{{(item.count*item.price).toFixed(2)}}</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;">{{item.remark}}</td>
							</tr>
							<tr style="line-height: 12px;">
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;border-left :1px #333 solid;">合计</td>
								<td align="center" style="border-right :1px #333 solid;border-top :1px #333 solid;" colspan="6">{{header.PRICE}}元</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr name="rFoot" style="line-height: 6px">
						<div style="font-size: 4px; padding-left: 10px; width: 70%;">
							<span style="float: left;">
								公司电话：{{header.RECIVE_TEL}}
							</span>
							<span style="float: left; padding-left: 200px;">
								地址：{{header.RECIVE_ADDRESS}}
							</span>
						</div>
						<br />
						<br />
						<br />
						<div style="font-size: 4px; padding-left: 10px; width: 70%;">
							<span style="float: left; ">
								收货单位及经手人：{{header.RECIVE_NAME}}
							</span>
							<span style="float: right;">
								送货单位及经手人：{{header.INNER_NAME}}
							</span>
						</div>
				</tr>
			</table>			
		</div>
		
	</div>
</template>

<script>
	import {api} from "../../../common/request/api/index";
	import pay from '../../../assets/img/pay.jpg';
	import Vue from 'vue';
	import {
		Tab,Tabs,PullRefresh,Toast,Image
	} from 'vant'
	Vue.use(Tab).use(Tabs).use(PullRefresh).use(Toast).use(Image);
	import footerTabbar from '../../../components/footerTabbar.vue'
	import myNarBar from '../../../components/myNarBar.vue'
	export default {
		name: "print",
		components: {
			footerTabbar,
			myNarBar
		},
		data() {
			return {
				isLoading: false,
				detailList: [],
				images: [pay],
				header: {},
			}
		},
		created() {
			this.orderId = this.$route.query.orderId;
			this.queryMyOrder()
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			queryMyOrder(){
				api.queryMyOrderBackFree({orderId: this.orderId}).then(res => {
					this.header = res[0]
					if(res[0].relList.length == 0){
						this.detailList = res[0].imgList
					}else{
						this.detailList = res[0].relList
					}
					this.isLoading = false
				})
			},
			formatDate(time) {
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
		},
		mounted() {
		},
	};
</script>

<style scoped>

	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    font-size: 14px;
	    background-color: #fff;
		margin: 10px;
		border-radius: 5px;
	}
	
	.fileList span {
		font-size: 14px;
	}
	
	.shopCardClass {
		background-color: white;
		border: 1px;
		z-index: 999999; 
		position: fixed;
		bottom: 0; 
		width: 100%; 
		height: 60px; 
	}
	
	.buyClass {
		float: right; 
		padding: 3px 10px; 
		background-color: red;
		margin: 0px 10px;
		border-radius: 50px;
		font-size: 10px;
		color: white;
	}
	
	.countClass {
		font-size: 20px;
		padding-right: 10px;
	}
	
	.allDiv {
		width: 100%;
		height: 100px;
		display: inline-block;
	}
	
	.leftDiv {
		padding-top: 30px;
		width: 20%;
		height: 200px;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.rightDiv {
		padding-top: 30px;
		margin-left: 20%;
		width: 80%;
		height: 200px;
		text-align: left;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.titleClass {
		text-align: left; 
		padding-left: 10px; 
		font-size: 20px;
	}
	
	.addressClass {
		font-size: 14px;
	}
	
	.statusClass {
		font-size: 14px;
		padding: 0px 5px; 
		margin-right: 10px;
		border-radius: 5px;
		background-color: aquamarine;
	}
	
	.btnClass {
		float: right;
		font-size: 14px;
		padding: 0px 5px; 
		margin-right: 10px;
		border-radius: 5px;
		color: white;
		background-color: royalblue;
	}
</style>
