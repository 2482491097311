<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">进货明细</div>
			</myNarBar>
		</van-sticky>
		<div class="content">
			<van-pull-refresh v-model="isLoading" @refresh="refleshFetchData">
				<div class="cardClass" v-for="(item,index) in comemeterList" :key="index">
					<div>
						<div style="text-align: left;font-weight: 700;padding-left: 10px;">
							{{item.relName}}
						</div>
						<span style="float: right;padding-right: 10px;">
							<div>
								<van-button style="margin-right: 10px;" type="primary" size="mini" @click="handleIn(index, item, '入库')">入库</van-button>
								<van-button style="margin-right: 10px;" type="info" size="mini" @click="handleIn(index, item, '备货')">备货</van-button>
							</div>
						</span>
					</div>
					<div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
							单位：<input style="width: 100px" disabled v-model="item.unit"></span>
						</div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
							总数：<input style="width: 100px" disabled v-model="item.amount"></span>
						</div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
							入库数量：<input style="width: 100px" disabled v-model="item.relAmount"></span>
						</div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
							总价：<input style="width: 100px" disabled v-model="item.price"></span>
						</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>
		
		<el-dialog
			title="填写入库数量"
			:visible.sync="showWarehoseIn"
			width="80%"
			class="dialog-form"
		>
			<van-radio-group v-model="warehouse" direction="horizontal">
			  <van-radio v-for="(item,index) in warehoseList" :name="item.id" :key="index">{{item.lovName}}</van-radio>
			</van-radio-group>
			<van-field
				v-model="reportCount" 
				label="入库数量" 
				placeholder="请输入入库数量" 
				required
				:rules="[{ required: true }]"
			/>
			<div>
				<van-button round type="info" size="normal">取 消</van-button>
				<van-button round type="info" size="normal" @click="insertUpdateWareHouseIn">确 定</van-button>
			</div>
		</el-dialog>
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,DropdownMenu,DropdownItem,Tab,Tabs,SwipeCell 
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(DropdownMenu)
	.use(DropdownItem).use(Tab).use(Tabs).use(SwipeCell)
	export default {
		name: "comeMeterDetail",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				isLoading: false,
				loading: false,
				finished: false,
				offset: 20, //滚动条与底部距离小于offset时触发load事件
				comemeterList: [],
				listQuery: {
					headerId: '',
				},
				showAdd: false,
				handelType: '',
				
				comemeter: {
					id: '',
					name: '',
					relName: '',
					relAmount: '',
					price: '',
					amount: '',
					unit: '',
					warehouse: '',
					status: '',
					remark: '',
					deleteFlag: '',
					createdById: '',
					createdAt: '',
					updatedById: '',
					updatedAt: '',
				},
				
				showFoodCat: false,
				foodCatList: [],
				
				showWarehoseIn: false,
				
				showWarehose: false,
				warehouse: '',
				warehoseList: [],
				
				showName: false,
				warehouse: '',
				status: '',
				nameSource: [],
				nameList: [],
				showReturnWarehose: false,
				reportCount: '',
				reportReturnCount: '',
				
				
			}
		},
		created() {
			this.listQuery.headerId = this.$route.query.headerId;
			
			api.queryComemeterDetail(this.listQuery).then(res => {
				res.forEach(item => {
					item.createdAt = this.formatDateHMS(item.createdAt)
					item.updatedAt = this.formatDateHMS(item.updatedAt)
				})
				this.comemeterList = res
			}, err => {
				Toast(err.data.msg);
			});
			
			api.queryLovMember({parentId: '0220fa3fe61553a638f3a0b9417e3bbc',notOptTxt1: 'S'}).then(res => {
				this.warehoseList = res
			})
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			refleshFetchData(){
				this.comemeterList = [];
				this.fetchData();
			},
			fetchData() {
				let that = this
				api.queryComemeterDetail(this.listQuery).then(res => {
					res.forEach(item => {
						item.createdAt = that.formatDateHMS(item.createdAt)
						item.updatedAt = that.formatDateHMS(item.updatedAt)
					})
					this.comemeterList = res
					this.isLoading = false;
				}, err => {
					Toast(err.data.msg);
					this.isLoading = false;
				});
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(item, index){
				var deleteFlag =  item.deleteFlag == 'Y'?'N':'Y';
				api.deleteComemeter({id: item.id, deleteFlag: deleteFlag}).then(res => {
					Toast("操作成功")
					this.comemeterList = this.comemeterList.filter((item, i) => index != i);
				})
			},
			saveComeMeter(comemeter,index){
				api.insertUpdateComemeterAgent(comemeter).then(res => {
					Toast("操作成功")
				}, err => {
					if (err) tip(err.data.msg||'稍后重试');
				})
			},
			handleIn(index, item, status){
				this.showWarehoseIn = !this.showWarehoseIn;
				this.comemeter = item;
				this.status = status;
				this.reportCount = this.comemeter.amount-this.comemeter.relAmount
				this.comemeter.createdAt = this.formatDateHMS(this.comemeter.createdAt)
				this.comemeter.updatedAt = this.formatDateHMS(this.comemeter.updatedAt)
			},
			insertUpdateWareHouseIn(){
				if(this.reportCount == "" || this.reportCount > this.comemeter.amount || this.warehouse == ""){
					Toast("入库数量或仓库错误")
				}else{
					console.log(this.warehouse)//成品仓：4d64696c928838e734d140b704ce07e3 半成品仓： 9daff5139ffd474917291113ac28c1de
					this.comemeter.status = this.status;
					this.comemeter.relAmount = this.reportCount
					this.comemeter.warehouse = this.warehouse;
					this.insertUpdateComemeterReport()
					this.showWarehoseIn = !this.showWarehoseIn;
				}
			},
			insertUpdateComemeterReport(){
				var params = {comemeter: this.comemeter}
				api.insertUpdateComemeterReport(this.comemeter).then(res => {
					Toast("操作成功")
					this.comemeterList = [];
					this.fetchData();
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.content {
		overflow-x: hidden;
		padding: 0px 0px 100px;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    background-color: #fff;
		margin: 5px;
		border-radius: 5px;
	}
	.cardClass label {
		text-align: left;
	}
	.icon {
		float: right;
		padding: 2.5px;
	}
</style>
