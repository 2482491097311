<template>
	<div class="content">
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">供应商进货明细</div>
			</myNarBar>
		</van-sticky>
		<div class="contentClass">
			<van-pull-refresh v-model="isLoading" @refresh="refleshFetchData">
				<div class="cardClass" v-for="(item,index) in comemeterList" :key="index">
					<div>
						<div style="text-align: left;font-weight: 700;padding-left: 10px;">
							{{item.relName}}
						</div>
						<span style="float: right;padding-right: 10px;">
							<div>
								<van-button 
									v-if="listQuery.status != '入库'"
									style="margin-right: 10px;"
									type="danger" size="mini"
									@click="handleDelete(item,index)">删除
								</van-button>
								<van-button 
									v-if="listQuery.status != '入库'"
									style="margin-right: 10px;" 
									type="primary" 
									size="mini" 
									@click="saveComeMeter(item,index)">保存
								</van-button>
							</div>
						</span>
					</div>
					<div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
							单位：<input style="width: 100px" disabled v-model="item.unit"></span>
						</div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
							总数：<input style="width: 100px" v-model="item.amount"></span>
						</div>
						<div style="text-align: left;padding-top: 2px;padding-left: 10px;">
							总价：<input style="width: 100px" v-model="item.price"></span>
						</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>		
		<!-- 底部 -->
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,DropdownMenu,DropdownItem,Tab,Tabs,SwipeCell 
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(DropdownMenu)
	.use(DropdownItem).use(Tab).use(Tabs).use(SwipeCell)
	export default {
		name: "agentComeDetail",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				isLoading: false,
				loading: false,
				finished: false,
				offset: 20, //滚动条与底部距离小于offset时触发load事件
				comemeterList: [],
				listQuery: {
					headerId: '',
					status: '',
				},
				showAdd: false,
				handelType: '',
				
				comemeter: {
					id: '',
					name: '',
					relName: '',
					price: '',
					amount: '',
					unit: '',
					warehouse: '',
					status: '',
					remark: '',
					deleteFlag: '',
					createdById: '',
					createdAt: '',
					updatedById: '',
					updatedAt: '',
				},
				
				showFoodCat: false,
				foodCatList: [],
				
				showWarehoseIn: false,
				
				showWarehose: false,
				warehouse: '',
				warehoseList: [],
				
				showName: false,
				warehouse: '',
				nameSource: [],
				nameList: [],
				showReturnWarehose: false,
				reportCount: '',
				reportReturnCount: '',
			}
		},
		created() {
			this.listQuery.headerId = this.$route.query.headerId;
			this.listQuery.status = this.$route.query.status;
			
			api.queryComemeterDetailAgent(this.listQuery).then(res => {
				res.forEach(item => {
					item.createdAt = this.formatDateHMS(item.createdAt)
					item.updatedAt = this.formatDateHMS(item.updatedAt)
				})
				this.comemeterList = res
			}, err => {
				Toast(err.data.msg);
			});
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			refleshFetchData(){
				this.comemeterList = [];
				this.fetchData();
			},
			fetchData() {
				let that = this
				api.queryComemeterDetailAgent(this.listQuery).then(res => {
					res.forEach(item => {
						item.createdAt = that.formatDateHMS(item.createdAt)
						item.updatedAt = that.formatDateHMS(item.updatedAt)
					})
					this.comemeterList = res
					this.isLoading = false;
				}, err => {
					Toast(err.data.msg);
					this.isLoading = false;
				});
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(item, index){
				var deleteFlag =  item.deleteFlag == 'Y'?'N':'Y';
				api.deleteComemeter({id: item.id, deleteFlag: deleteFlag}).then(res => {
					Toast("操作成功")
					this.comemeterList = this.comemeterList.filter((item, i) => index != i);
				})
			},
			saveComeMeter(comemeter,index){
				api.insertUpdateComemeterAgent(comemeter).then(res => {
					Toast("操作成功")
				}, err => {
					if (err) tip(err.data.msg||'稍后重试');
				})
			},
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.content {
		background-color: #f5f5f5;
		height: auto;
	}
	.contentClass {
		margin: 0px 0px 80px 0px;
	}
	.cardClass {
	    position: relative;
	    box-sizing: border-box;
	    padding: 4px 0px;
	    color: #323233;
	    background-color: #fff;
		margin: 5px;
		border-radius: 5px;
	}
	.cardClass label {
		text-align: left;
	}
	.icon {
		float: right;
		padding: 2.5px;
	}
</style>
