<template>
	<div>
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">商品种类</div>
				<div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="addfoodCat" />
				</div>
			</myNarBar>
		</van-sticky>
		<div class="content">
			<input v-model="listQuery.name"/>
			<button @click="fetchTreeData">刷新/查询</button>
			<div>
				 <el-table
				    :data="foodCatList"
				    stripe
				    style="width: 100%">
				    <el-table-column
						prop="name"
						label="名称"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="orderNum"
						label="序号"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="deleteFlag"
						label="状态"
						width="100">
						<template slot-scope="scope">
							<span :class="scope.row.deleteFlag == 'Y'?'unValid':'valid'">{{ scope.row.deleteFlag == "Y" ? "无效" : "有效" }}</span>
						</template>
				    </el-table-column>
					<el-table-column label="操作">
					  <template slot-scope="scope">
						<el-button
							size="mini"
							@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="handleDelete(scope.$index, scope.row)">删除</el-button>
					  </template>
					</el-table-column>
				  </el-table>
			</div>
			
			<el-dialog
				title="新增/编辑"
				:visible.sync="showAdd"
				width="100%"
				class="dialog-form"
			>
				<van-field 
					v-model="foodCat.name" 
					label="名称" 
					placeholder="请输入名称" 
					required
				/>
				<van-field 
					v-model="foodCat.orderNum" 
					label="序号" 
					placeholder="请输入序号" 
					required
				/>
				<van-field 
					v-model="foodCat.deleteFlag" 
					label="是否有效(Y/N)" 
					placeholder="请输入Y或者N" 
					required
				/>
			
				<div>
					<el-button @click="addfoodCat">取 消</el-button>
					<el-button type="primary" @click="foodCatMethod">确 定</el-button>
				</div>
			</el-dialog>
		</div>
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,DropdownMenu,DropdownItem,Tab,Tabs,SwipeCell,RadioGroup,Radio
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(DropdownMenu).use(RadioGroup)
	.use(DropdownItem).use(Tab).use(Tabs).use(SwipeCell).use(Radio);
	export default {
		name: "foodCat",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				foodCatList: [],
				listQuery: {
					name: '',
				},
				showAdd: false,
				
				foodCat: {
					id: '',
					name: '',
					orderNum: '',
					deleteFlag: '',
				},
			}
		},
		created() {
			this.fetchTreeData()
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			fetchTreeData() {
			  api.queryFoodCat(this.listQuery).then(res => {
			    this.foodCatList = res
			    this.listLoading = false
			  }).catch(() => {
			    this.listLoading = false
			  })
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(index, item){
				api.deleteFoodCat({id: item.id}).then(res => {
					Toast("操作成功")
					this.fetchTreeData();
				})
			},
			addfoodCat(){
				this.showAdd = !this.showAdd;
			},
			handleEdit(index, item){
				console.log(index, item);
				this.showAdd = !this.showAdd;
				this.foodCat = item;
				this.foodCat.createdAt = this.formatDateHMS(this.foodCat.createdAt)
				this.foodCat.updatedAt = this.formatDateHMS(this.foodCat.updatedAt)
			},
			foodCatMethod(){
				api.insertUpdateFoodCat(this.foodCat).then(res => {
					Toast("操作成功")
					this.showAdd = !this.showAdd;
					this.fetchTreeData();
				})
			}
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.content {
		overflow-x: hidden;
		padding: 0px 0px 100px;
	}
</style>
