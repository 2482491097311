<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="title">
				<div slot="title">个人</div>
			</myNarBar>
		</van-sticky>

		<div class="content">
			<van-cell-group>
				<van-cell title="姓名" size="large" :value="userInfo.name" />
				<van-cell title="电话" size="large" :value="userInfo.phone" />
				<van-cell title="店铺名称" size="large" :value="userInfo.shopName" />
				<van-cell title="地址" size="large" :value="userInfo.address" />
				<van-cell v-if="userInfo.name == 'admin'" title="休息开始时间" size="large" :value="userInfo.startDate" @click="openStartDate" />
				<van-cell v-if="userInfo.name == 'admin'" title="休息结束时间" size="large" :value="userInfo.endDate" @click="openEndDate" />
			</van-cell-group>
		</div>
		<van-popup v-model="showStartDate" position="bottom">
			<van-datetime-picker
				v-model="userInfo.startDate"
				type="time"
				title="开始时间"
				:min-hour="8"
				:max-hour="20"
				@confirm="confirmDate('start')"
			/>
		</van-popup>
		<van-popup v-model="showEndDate" position="bottom">
			<van-datetime-picker
				v-model="userInfo.endDate"
				type="time"
				title="开始时间"
				:min-hour="8"
				:max-hour="20"
				@confirm="confirmDate('end')"
			/>
		</van-popup>

		<div class="content1" v-if="userInfo.type == 'inner' || userInfo.type == 'agent'">
			<van-field
				readonly 
				is-link
				label="后台管理"
				@click="toBackManager"
			/>
			</van-dialog>
		</div>
		
		<div class="content1">
			<van-field
				readonly 
				is-link
				label="修改密码"
				@click="updatePasswordDialog()"
			/>
			<van-dialog v-model="show" show-cancel-button @confirm="updatePassword" style="width: 100%; height: 30%;">
				<van-form>
					<van-field
						v-model="passwordData.oldPassword"
						name="oldPassword"
						label="旧密码"
						placeholder="旧密码"
						type="password"
					/>
					<van-field
						v-model="passwordData.newPassword"
						name="newPassword"
						label="新密码"
						placeholder="新密码"
						type="password"
					/>
					<van-field
						v-model="passwordData.reNewPassword"
						name="reNewPassword"
						label="确认新密码"
						placeholder="确认新密码"
						type="password"
					/>
			  </van-form>
			</van-dialog>
		</div>
		
		<van-button type="danger" @click="logout()" :disabled="disabled" size="normal" block>退出</van-button>
		<!-- 底部 -->
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>
<script>
	import {api} from "../../common/request/api/index";
	import footerTabbar from '../../components/footerTabbar.vue'
	import myNarBar from '../../components/myNarBar.vue'
	import Vue from 'vue';
	import {
		Sticky,
		Popup,
		Dialog,
		Search,
		Toast,
		Button,
		Cell,
		CellGroup,DatetimePicker
	} from 'vant'
	Vue.use(Sticky).use(Popup).use(Dialog).use(Search).use(Toast).use(Button).use(Cell).use(CellGroup).use(DatetimePicker)

	export default {
		data() {
			return {
				userInfo: {
					name: '',
					phone: '',
					type: '',
					startDate: '',
					endDate: '',
				},
				disabled: false,
				show: false,
				passwordData: {
					phone: '',
					oldPassword: '',
					newPassword: '',
					reNewPassword: '',
				},
				showStartDate: false,
				showEndDate: false,
			}
		},
		created() {
			// this.userInfo = JSON.parse();
			var userObject = localStorage.getItem("userObject");
			if("" == userObject){
				this.$router.push({
					path: '/login',
				});
			}else{
				this.userInfo = JSON.parse(userObject);
			}
		},
		methods: {
			logout() {
				api.logout();
			},
			openStartDate(type){
				this.showStartDate = !this.showStartDate
			},
			openEndDate(type){
				this.showEndDate = !this.showEndDate
			},
			confirmDate(type){
				if(type == "start"){
					this.showStartDate = !this.showStartDate
				}else{
					this.showEndDate = !this.showEndDate
				}
				api.insertUpdateEmployeeDate({id: this.userInfo.id, startDate: this.userInfo.startDate, endDate: this.userInfo.endDate}).then(res => {
					Toast("修改成功");
					this.userInfo = res.employee;
					localStorage.setItem("userObject", JSON.stringify(res.employee));
				})
			},
			toBackManager(){
				if(this.userInfo.phone == ""){
					this.$router.push({
						path: '/login',
					});
				}else{
					this.$router.push({
						path: '/backManager',
					});
				}
			},
			formatDate(time) {
				return new Date(time).format("yyyy-MM-dd hh:mm:ss")
			},
			updatePasswordDialog(){
				this.show = !this.show
				this.passwordData.no = this.userInfo.employee.no
			},
			updatePassword(){
				var reg = new RegExp('^(?=(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_])|(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])|(?=.*[0-9])(?=.*[a-z])(?=.*[\\W_])|(?=.*[0-9])(?=.*[A-Z])(?=.*[\\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]))[a-zA-Z0-9\\W_]{8,}$');
				if(this.passwordData.newPassword != this.passwordData.reNewPassword){
					Toast("确认新密码错误")
				}else if(!reg.test(this.passwordData.newPassword)){
					Toast('密码至少8位，要求：大写字母、小写字母、数字、特殊字符，三种以上，如Qq12345');
				}else{
					api.updatePassword(this.passwordData).then(res => {
						Toast(res)
					},error => {
						console.log(error)
						Toast(error.data.msg || error.data.message)
					})
				}
			},
		},
		components: {
			footerTabbar,
			myNarBar,
		},
	};
</script>

<style scoped>
	.content {
		background-color: #d5d5d5;
		height: auto;
	}
	.content1 {
		padding-top: 10px;
		background-color: #f5f5f5;
		height: auto;
	}
	
	.van-cell__title{
		text-align: left;
		color: #969799;
	}
	
	.van-cell__value{
		min-width: 60%;
	}
</style>
