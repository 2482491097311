<template>
	<div class="content" style="padding: 10px;">
			<div class="tilte">常用功能</div>
			<div class="swiper-item">
				<div v-for="(menu, index) in menuList" :key="index" v-if="Permission(menu.permission)" @click="toNextPage(menu.url)" class="kongKimCard">
					<div>
						<van-image width="35" height="35" :src="menu.icon"></van-image>
					</div>
					<div>{{menu.text}}</div>
				</div>
			</div>
	</div>
</template>

<script>
	export default {
		name: 'commonUsed',
		data() {
			return {
				menuList: [
					{
						id: 1,
						icon: require('../../../assets/icon/genjin.png'),
						text: "商品种类",
						url: "/crm/foodCat",
						permission: "d1c79538631cb39036db25303a701b1e"
					},
					{
						id: 2,
						icon: require('../../../assets/icon/shopIcon.png'),
						text: "商品",
						url: "/crm/food",
						permission: "6b94d8e1a96df89c7c2bdf536ec4033b"
					},
					{
						id: 3,
						icon: require('../../../assets/icon/kehu.png'),
						text: "人员",
						url: "/crm/userManager",
						permission: "14e4c81d73ea9d8f9b88a16959084e4e"
					},
					{
						id: 3.1,
						icon: require('../../../assets/icon/huikuan.png'),
						text: "折扣",
						url: "/crm/userSales",
						permission: "a74581024771806bcb1c2aa4c01cb335"
					},
					{
						id: 4,
						icon: require('../../../assets/icon/chachong.png'),
						text: "供应商进货",
						url: "/crm/agentCome",
						permission: "042f6d84e11bd3e4402259e790a31d24"
					},
					{
						id: 5,
						icon: require('../../../assets/icon/chachong.png'),
						text: "库存日志",
						url: "/crm/warehouseLog",
						permission: "708e65e7fc16b2d9746494fc21bed224"
					},
					{
						id: 6,
						icon: require('../../../assets/icon/chachong.png'),
						text: "库存管理",
						url: "/crm/warehouseManager",
						permission: "8e9bdc3c67b17b61b62a2eda851e227e"
					},
					{
						id: 7,
						icon: require('../../../assets/icon/baojiadan.png'),
						text: "订单管理",
						url: "/crm/orderManager",
						permission: "d6d8b8616f20274a1a3b2472b2e61d6d"
					},
					{
						id: 8,
						icon: require('../../../assets/icon/biaodan.png'),
						text: "报表管理",
						url: "/crm/reportManager",
						permission: "70fab7ea8442c29ed54a0002371bf444"
					},
					{
						id: 9,
						icon: require('../../../assets/icon/guanwang.png'),
						text: "字典管理",
						url: "/crm/lovMember",
						permission: "6ccadc7e070aef463d168a2bf3acb017"
					},
				],
				loading: false,
				finished: false
			}
		},
		methods: {
			toNextPage(url){
				console.log(url)
				this.$router.push({
					path: url,
				});
			}
		},
		components: {

		}
	};
</script>

<style scoped>

	.tilte {
		text-align: left;
		margin: 0px 0px;
		padding: 2px;
		font-size: 16px;
		font-weight: 700;
	}

	.swiper-item {
		/* display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px 0px; */
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 13px 13px 0;
	}
	
	.kongKimCard {
		width: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.kongKimCard>div:first-child {
		width: 100rpx;
		height: 100rpx;
		border-radius: 20rpx;
	}

	.kongKimCard>div:first-child>van-image {
		width: 64rpx;
		height: 64rpx;
		margin: 18rpx;
	}

	.kongKimCard>div:last-child {
		text-align: center;
		font-size: 13px;
		font-weight: bold;
		margin-top: 8rpx;
	}
	
	.content {
		background-color: #fff;
		height: auto;
	}

	.box-card {
		width: auto;
	}
</style>
