<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">折扣</div>
				<div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="addSales" />
				</div>
			</myNarBar>
		</van-sticky>
		<div class="content">
			<input v-model="listQuery.name" />
			<button @click="fetchData">刷新/查询</button>
			<div>
				<el-table :data="salesList" stripe style="width: 100%">
					<el-table-column prop="userName" label="姓名" width="80">
					</el-table-column>
					<el-table-column prop="foodName" label="商品名称" width="120">
					</el-table-column>
					<el-table-column prop="sales" label="折扣" width="80">
					</el-table-column>
					<el-table-column prop="price" label="单价" width="80">
					</el-table-column>
					<el-table-column prop="salesPrice" label="折后价" width="80">
					</el-table-column>
					<el-table-column label="操作" width="150">
						<template slot-scope="scope">
							<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
							<el-button size="mini" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background :pager-count=5 class="paginationClass" layout="prev, pager, next"
					@current-change="handleCurChange" @click="" :total="1000">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="折扣新增/编辑" :visible.sync="showAddSales" width="80%" class="dialog-form">
			<van-field v-model="sales.userName" label="会员" placeholder="请选择会员" required
				:rules="[{ required: true }]" @click="showUser = true" />
			<van-popup v-model="showUser" position="bottom">
				<van-picker show-toolbar :columns="userList" @confirm="onUser" @cancel="showUser = false" />
			</van-popup>
			<van-radio-group v-model="sales.foodId" direction="horizontal">
				<van-radio v-for="(item,index) in foodList" :name="item.id" :key="index">{{item.lovName}}</van-radio>
			</van-radio-group>
			<van-field v-model="sales.sales" label="折扣" placeholder="请输入折扣,1到9" required
				:rules="[{ required: true }]" />
			<van-field v-model="sales.price" label="单价" placeholder="请输入单价" required :rules="[{ required: true }]" />
			<div>
				<van-button round type="info" size="normal">取 消</van-button>
				<van-button round type="info" size="normal" @click="insertUpdateSales">确 定</van-button>
			</div>
		</el-dialog>

		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {
		api
	} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,
		Popup,
		Form,
		Field,
		PullRefresh,
		Toast,
		List,
		Dialog,
		Tab,
		Tabs,
		SwipeCell,
		Image,
		Radio,
		RadioGroup
	} from 'vant';
	import {
		tip
	} from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
		.use(Toast).use(List).use(Dialog).use(Radio).use(RadioGroup)
		.use(Tab).use(Tabs).use(SwipeCell).use(Image);
	export default {
		name: "userSales",
		components: {
			myNarBar,
			addBtn,
			footerTabbar
		},
		data() {
			return {
				listLoading: true,
				salesList: [],
				listQuery: {
					id: '',
					name: '',
					curPage: '1',
					pageSize: '10',
				},
				sales: {
					id: '',
					userId: '',
					userName: '',
					foodId: '',
					foodName: '',
					sales: '',
					price: '',
				},
				showAddSales: false,

				foodList: [],

				showUser: false,
				userSource: [],
				userList: [],
			}
		},
		created() {
			this.fetchData();

			api.queryLovMember({
				parentId: '14a10a60d5012552207f83d1bce0352a',optTxt1: '3'
			}).then(res => {
				this.foodList = res
			})

			api.queryEmployee().then(res => {
				this.userSource = res
				this.userList = res.map(function(data) {
					return data.name + "|" + data.phone
				});
			})
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			onUser(value) {
				var v = this.userSource.find(item => item.phone == value.split("|")[1]);
				this.sales.userId = v.id;
				this.sales.userName = v.name;
				this.showUser = false;
			},
			fetchData() {
				api.queryEmployeeSales(this.listQuery).then(res => {
					this.salesList = res
					this.listLoading = false
				}).catch(() => {
					this.listLoading = false
				})
			},
			handleCurChange(val) {
				this.listQuery.curPage = val;
				this.fetchData();
			},
			formatDate(time) {
				if (null == time || "" == time) {
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if (null == time || "" == time) {
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(index, item) {
				this.sales = item
				this.sales.deleteFlag = "Y"
				this.insertUpdateSales('delete');
			},
			addSales() {
				this.showAddSales = !this.showAddSales
			},
			handleEdit(index, row) {
				console.log(index, row)
				this.showAddSales = !this.showAddSales
				this.sales = row
			},
			insertUpdateSales(type) {
				this.sales.createdAt = this.formatDateHMS(this.sales.createdAt)
				this.sales.updatedAt = this.formatDateHMS(this.sales.updatedAt)
				api.insertUpdateEmployeeSales(this.sales).then(res => {
					Toast("操作成功")
					if (type != 'delete') {
						this.showAddSales = !this.showAddSales
					}
					this.salesList = []
					this.fetchData();
				})
			},
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}

	.valid {
		color: green;
	}

	.agent {
		color: blue;
	}

	.content {
		overflow-x: hidden;
		padding: 0px 0px 100px;
	}

	.paginationClass {
		text-align: center;
		margin-top: 5px;
		width: 100%;
	}
</style>