<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">库存管理</div>
				<div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="addComemeter" />
				</div>
			</myNarBar>
		</van-sticky>
		<input v-model="listQuery.name"/>
		<button @click="fetchTreeData">刷新/查询</button>
		<div class="content">
			 <el-table
			    :data="comemeterList"
			    stripe
			    style="width: 100%">
			    <el-table-column
					prop="warehouseName"
					label="仓库"
					width="100">
			    </el-table-column>
			    <el-table-column
					prop="foodName"
					label="名称"
					width="80">
			    </el-table-column>
			    <el-table-column
					prop="relCount"
					label="数量"
					width="60">
			    </el-table-column>
			    <el-table-column
					prop="amount"
					label="数量"
					v-if="false"
					width="60">
			    </el-table-column>
			    <el-table-column
					prop="relAmount"
					label="已入库数量"
					v-if="false"
					width="60">
			    </el-table-column>
			    <el-table-column
					prop="unit"
					label="单位"
					width="60">
			    </el-table-column>
			    <el-table-column
					prop="createdAt"
					label="入库时间"
					width="100">
			    </el-table-column>
				<el-table-column label="操作" width="250">
				  <template slot-scope="scope">
					<el-button
						size="mini" v-if="scope.row.warehouse != '4d64696c928838e734d140b704ce07e3'"
						@click="handleIn(scope.$index, scope.row)">入库</el-button>
					<el-button
						size="mini" v-if="scope.row.warehouse != '23adb7199879109df324506c90ac486b' && scope.row.unit != '斤' && scope.row.amount != '0'"
						@click="handleReportReturn(scope.$index, scope.row)">退货</el-button>
					<el-button
						size="mini" v-if="scope.row.warehouse == '4d64696c928838e734d140b704ce07e3' && scope.row.unit != '斤' && scope.row.amount != '0'"
						@click="changeUnit(scope.$index, scope.row)">单位转换</el-button>
				  </template>
				</el-table-column>
			  </el-table>
			  <!-- <el-pagination
			    background
			    layout="prev, pager, next"
			    :total="1000">
			  </el-pagination> -->
		
			<el-dialog
				title="填写入库数量"
				:visible.sync="showWarehoseIn"
				width="100%"
				class="dialog-form"
			>
				<div class="nameClass">仓库</div>
				<van-radio-group v-model="warehouse" direction="horizontal">
				  <van-radio v-for="(item,index) in warehoseList" v-if="item.id != '23adb7199879109df324506c90ac486b' && (item.id != comemeter.warehouse)" :name="item.id" :key="index">{{item.lovName}}</van-radio>
				</van-radio-group>
				<div class="nameClass">商品名称</div>
				<van-radio-group v-model="food" direction="horizontal">
				  <van-radio v-for="(item,index) in foodList" v-if="(item.optTxt2.indexOf(comemeter.optTxt2) != -1) && (item.optTxt1 != comemeter.optTxt1)" :name="item.id" :key="index">{{item.lovName}}</van-radio>
				</van-radio-group>
				<van-field
					v-model="changeAmount" 
					label="入库数量" 
					placeholder="请输入入库数量" 
					required
					:rules="[{ required: true }]"
				/>
				<div>
					<van-button round type="info" size="normal">取 消</van-button>
					<van-button round type="info" size="normal" @click="insertUpdateWareHouseIn">确 定</van-button>
				</div>
			</el-dialog>
		</div>
		
		<el-dialog
			title="填写退货数量"
			:visible.sync="showReturnWarehose"
			width="100%"
			class="dialog-form"
		>
			<div class="nameClass">仓库</div>
			<van-radio-group v-model="warehouse" direction="horizontal">
			  <van-radio v-for="(item,index) in warehoseList" v-if="item.id != '4d64696c928838e734d140b704ce07e3' && (item.id != comemeter.warehouse)" :name="item.id" :key="index">{{item.lovName}}</van-radio>
			</van-radio-group>
			<div class="nameClass">商品名称</div>
			<van-radio-group v-model="food" direction="horizontal">
			  <van-radio v-for="(item,index) in foodList" v-if="(comemeter.optTxt2.indexOf(item.optTxt2) != -1) && (item.optTxt1 != comemeter.optTxt1) && item.optTxt1 != '3'" :name="item.id" :key="index">{{item.lovName}}</van-radio>
			</van-radio-group>
			<van-field
				v-model="changeAmount" 
				label="退货数量" 
				placeholder="请输入退货数量" 
				required
				:rules="[{ required: true }]"
			/>
			<div>
				<van-button round type="info" size="normal">取 消</van-button>
				<van-button round type="info" size="normal" @click="insertUpdateComemeterReturn">确 定</van-button>
			</div>
		</el-dialog>
		
		<el-dialog
			title="填写转换数量"
			:visible.sync="showChange"
			width="80%"
			class="dialog-form"
		>
			<van-field
				v-model="changeAmount" 
				label="转换数量"
				placeholder="请输入转换数量" 
				required
				:rules="[{ required: true }]"
			/>
			<van-field
				v-model="afterAmount" 
				label="转换后数量"
				placeholder="请输入转换后数量" 
				required
				:rules="[{ required: true }]"
			/>
			<div>
				<van-button round type="info" size="normal">取 消</van-button>
				<van-button round type="info" size="normal" @click="toChangeAmount">确 定</van-button>
			</div>
		</el-dialog>
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,DropdownMenu,DropdownItem,Tab,Tabs,SwipeCell,Image 
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(DropdownMenu)
	.use(DropdownItem).use(Tab).use(Tabs).use(SwipeCell).use(Image);
	export default {
		name: "comeMeter",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				comemeterList: [],
				listQuery: {
					name: '',
					status: 'Y',
				},
				showAdd: false,
				handelType: '',
				
				comemeter: {
					id: '',
					name: '',
					relName: '',
					price: '',
					amount: '',
					unit: '',
					warehouseName: '',
					warehouse: '',
					status: '',
					remark: '',
					deleteFlag: '',
					createdById: '',
					createdAt: '',
					updatedById: '',
					updatedAt: '',
					retWarehouse: '',
					retCount: '',
					changeAmount: '',
					afterAmount: '',
					toWarehouse: '',
					toFood: '',
					optTxt1: '',
					optTxt2: '',
				},
				
				showFoodCat: false,
				foodCatList: [],
				
				showWarehose: false,
				warehouse: '',
				warehoseList: [],
				
				showFood: false,
				food: '',
				foodList: [],
				
				showReturnWarehose: false,
				inAmount: '',
				retAmount: '',
				
				showWarehoseIn: false,
				
				showChange: false,
				changeAmount: '',
				afterAmount: '',
			}
		},
		created() {
			this.fetchTreeData();
			/* ,notOptTxt1: 'R' */
			api.queryLovMember({parentId: '0220fa3fe61553a638f3a0b9417e3bbc'}).then(res => {
				this.warehoseList = res
			})
			
			api.queryLovMember({parentId: '14a10a60d5012552207f83d1bce0352a'}).then(res => {
				this.foodList = res
			})
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			fetchTreeData() {
				let that = this
				api.queryComemeter(this.listQuery).then(res => {
					res.forEach(item => {
						item.createdAt = that.formatDate(item.createdAt)
					})
					this.comemeterList = res
					this.listLoading = false
				}).catch(() => {
					this.listLoading = false
				})
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			addComemeter(){
				this.showAdd = !this.showAdd;
				this.handelType = 'add';
				this.comemeter.id = '';
			},
			handleEdit(index, item){
				this.showAdd = !this.showAdd;
				this.handelType = 'edit';
				this.comemeter = item;
				this.comemeter.createdAt = this.formatDateHMS(this.comemeter.createdAt)
				this.comemeter.updatedAt = this.formatDateHMS(this.comemeter.updatedAt)
			},
			handleReport(index, item){
				this.showWarehose = !this.showWarehose;
				this.comemeter = item;
				this.reportCount = this.comemeter.amount
				this.comemeter.createdAt = this.formatDateHMS(this.comemeter.createdAt)
				this.comemeter.updatedAt = this.formatDateHMS(this.comemeter.updatedAt)
				// this.comemeterMethod()
			},
			handleReportReturn(index, item){
				this.showReturnWarehose = !this.showReturnWarehose;
				this.comemeter = item;
				this.retAmount = this.comemeter.amount
				this.comemeter.createdAt = this.formatDateHMS(this.comemeter.createdAt)
				this.comemeter.updatedAt = this.formatDateHMS(this.comemeter.updatedAt)
			},
			insertUpdateComemeterReturn(){
				debugger
				if(this.changeAmount == "" || this.changeAmount > this.comemeter.amount){
					Toast("退货数量不能为空并且不能大于库存数量")
				}else if(this.comemeter.warehouse == this.warehouse){
					this.showReturnWarehose = !this.showReturnWarehose;
					Toast("半成品仓不允许退货到半成品仓")
				}else{
					this.comemeter.toWarehouse = this.warehouse
					this.comemeter.toFood = this.food
					this.comemeter.changeAmount = this.changeAmount
					console.log(this.comemeter)
					api.handelReturn(this.comemeter).then(res => {
						Toast("操作成功")
						this.comemeterList = [];
						this.fetchTreeData();
					}, err => {
						if (err) tip(err.data.msg);
					})
				}
			},
			onSubmit(){
				this.comemeterMethod()
				this.showAdd = !this.showAdd;
			},
			comemeterMethod(){
				var params = {comemeter: this.comemeter}
				api.insertUpdateComemeter(this.comemeter).then(res => {
					Toast("操作成功")
					this.comemeterList = [];
					this.fetchTreeData();
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
			handleIn(index, item, status){
				this.showWarehoseIn = !this.showWarehoseIn;
				this.comemeter = item;
				this.status = status;
				this.changeAmount = this.comemeter.amount-(this.comemeter.relAmount||0)
				this.comemeter.createdAt = this.formatDateHMS(this.comemeter.createdAt)
				this.comemeter.updatedAt = this.formatDateHMS(this.comemeter.updatedAt)
			},
			insertUpdateWareHouseIn(){
				if(this.changeAmount == "" || this.changeAmount > (this.comemeter.amount-(this.comemeter.relAmount||0)) || this.warehouse == ""){
					Toast("入库数量或仓库错误")
				}else if(this.warehouse == this.comemeter.warehouse){
					Toast("半成品仓不能入库半成品仓")
				}else{
					console.log(this.warehouse)//成品仓：4d64696c928838e734d140b704ce07e3 半成品仓： 9daff5139ffd474917291113ac28c1de
					this.comemeter.changeAmount = this.changeAmount
					this.comemeter.toWarehouse = this.warehouse;
					this.comemeter.toFood = this.food;
					this.showWarehoseIn = !this.showWarehoseIn;
					api.handelIn(this.comemeter).then(res => {
						Toast(res)
						this.comemeterList = [];
						this.fetchTreeData();
					}, err => {
						if (err) tip(err.data.msg);
					})
				}
			},
			insertUpdateComemeterReport(){
				var params = {comemeter: this.comemeter}
				api.insertUpdateComemeterReport(this.comemeter).then(res => {
					Toast(res)
					this.comemeterList = [];
					this.fetchTreeData();
				}, err => {
					if (err) tip(err.data.msg);
				})
			},
			changeUnit(index, item){
				this.showChange = !this.showChange
				this.comemeter = item
			},
			toChangeAmount(){
				if(this.changeAmount == "" || this.changeAmount > this.comemeter.amount){
					Toast("转换数量错误")
				}else{
					this.comemeter.changeAmount = this.changeAmount
					this.comemeter.afterAmount = this.afterAmount
					this.comemeter.createdAt = this.formatDateHMS(this.comemeter.createdAt)
					this.comemeter.updatedAt = this.formatDateHMS(this.comemeter.updatedAt)
					api.changeAmount(this.comemeter).then(res => {
						Toast("操作成功")
						this.comemeterList = [];
						this.fetchTreeData();
					}, err => {
						if (err) tip(err.data.msg);
					})
				}
			},
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.content {
		overflow-x: hidden;
		padding: 0px 0px 120px;
	}
	.btnClass span {
		padding: 0px 0px 0px 5px;
	}
	.nameClass {
		text-align: left;
		font-size: 16px;
		color: #0e0e14;
		margin: 10px;
	}
</style>
