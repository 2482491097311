<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">上传图片</div>
			</myNarBar>
		</van-sticky>
		
		<div class="content">
			<div class="addAttachment">
				<van-form>
					<van-field name="uploader" label="图片" multiple>
						<template #input>
							<van-uploader v-model="fileList" />
						</template>
					</van-field>
					<div style="margin: 16px;">
						<van-button round block type="info" @click="onSubmit()">提交</van-button>
					</div>
				</van-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		api
	} from "../common/request/api/index";
	import myNarBar from './myNarBar.vue';
	import Vue from 'vue';
	import {
		Sticky,
		Popup,
		Form,
		Dialog,
		Field,
		Toast,
		Uploader
	} from 'vant'
	Vue.use(Sticky).use(Popup).use(Form).use(Dialog).use(Toast).use(Uploader).use(Field)
	export default {
		name: "addAttachment",
		data() {
			return {
				businessId: '',//业务ID
				fileList: [],//业务ID
			}
		},
		components: {
			myNarBar
		},
		created(){
			this.businessId = this.$route.query.businessId;
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			onSubmit() {
				var params = {'businessId': this.businessId, fileList: this.fileList}
				api.uploadFile(params);
				this.$router.go(-1)
			},
		},
	}
</script>

<style scoped>
	.content {
		background-color: #d5d5d5;
		height: auto;
		padding: 5px;
	}

	.title {
		font-size: 20px;
		text-align: left;
		font-weight: 900;
		background-color: #d5d5d5;
		padding-left: 5px;
	}

	.addAttachment {
		width: auto;
		background-color: white;
		padding: 10px 10px;
		border-radius: 10px;
		margin: 10px 0px;
		text-align: left;
	}
	
	.van-button--normal {
		padding: 0 45px;
	}
</style>
