<template>
	<div class="table-classic-wrapper">
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">资料</div>
				<div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="openAddFile" />
				</div>
			</myNarBar>
		</van-sticky>
		<van-sticky offset-top="50px">
			<van-search
				v-model="queryData.searchKey"
				show-action
				placeholder="请输入资料名称"
			>
				<template #action>
					<div @click="onSearch()">搜索</div>
				</template>
			</van-search>
		</van-sticky>
		<van-tabs v-model="active" @click="onCheckTab">
		  <van-tab title="未配置权限" name="N">
			  <van-pull-refresh v-model="loading" @refresh="onRefresh(true)">
			  	<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="10" @load="onLoad">
			  		<div class="content" v-for="(item, index) in list" :key="item.id" @click="editPermission(item)">
			  			<van-cell>
							<div>
								<span style="height: 900px; font-size: 18px;">{{item.attName}}</span>
								<span v-if="item.attSize" style="margin-left: 10px;height: 500px; background-color: aquamarine;">
									{{(item.attSize/1024/1024).toFixed(2)}}M
								</span>
								<span style="float: right;">
									<van-button size="mini" :color="item.isValid=='Y'?'green':'#707574'" :text="item.isValid=='Y'?'有效':'失效'" @click.stop="validFile(index, item.id, item.isValid)"/>
									<van-button size="mini" type="danger" text="删除" @click.stop="deleteFile(index, item.id)"/>
								</span>
							</div>
							<div>
								<span style="background-color: #7fbeff;">{{item.typeName}}</span>
							</div>
							<div>
								{{item.creatorName}}
							</div>
							<div>
								{{formatDateHMD(item.createdAt)}}
							</div>
			  			</van-cell>
			  		</div>
			  	</van-list>
			  </van-pull-refresh>
		  </van-tab>
		  <van-tab title="已配置权限" name="Y">
				<van-pull-refresh v-model="loading" @refresh="onRefresh()">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="10" @load="onLoad">
						<div class="content" v-for="(item,index) in list" :key="item.id" @click="editPermission(item)">
							<van-cell>
								<div>
									<span style="height: 900px; font-size: 18px;">{{item.attName}}</span>
									<span v-if="item.attSize" style="margin-left: 10px;height: 500px; background-color: aquamarine;">
										{{(item.attSize/1024/1024).toFixed(2)}}M
									</span>
									<span style="float: right;">
										<van-button size="mini" :color="item.isValid=='Y'?'green':'#707574'" :text="item.isValid=='Y'?'有效':'失效'" @click.stop="validFile(index, item.id, item.isValid)"/>
										<van-button size="mini" type="danger" text="删除" @click.stop="deleteFile(index, item.id)"/>
									</span>
								</div>
								<div>
									<span style="background-color: #7fbeff;">{{item.typeName}}</span>
								</div>
								<div>
									{{item.creatorName}}
								</div>
								<div>
									{{formatDateHMD(item.createdAt)}}
								</div>
							</van-cell>
						</div>
					</van-list>
				</van-pull-refresh>
		  </van-tab>
		</van-tabs>
		<el-dialog
		  title="上传附件"
		  :visible.sync="showAddFile"
		  width="100%"
		  class="dialog-form"
		  :before-close="handleUploadClose"
		>
			<el-upload
				class="upload-demo"
				ref="uploadfiles"
				action
			    drag
				:auto-upload="false"
				:on-remove="handleRemove"
				:on-change="handleChange"
				multiple
				:limit="10"
				:on-exceed="handleExceed"
				@file-list="uploadForm.fileList">
				  <i class="el-icon-upload"></i>
				  <div class="el-upload__text"><em>点击上传</em></div>
			</el-upload>
			<van-radio-group v-model="uploadForm.type" direction="horizontal">
			  <van-radio v-for="(item,index) in typeList" :name="item.id" :key="index">{{item.lovName}}</van-radio>
			</van-radio-group>
			<div>
				<el-button @click="cancleForm">取 消</el-button>
				<el-button type="primary" @click="uploadFile">确 定</el-button>
			</div>
		</el-dialog>
		
		<el-dialog
		  title="权限"
		  :visible.sync="permissionVisible"
		  width="100%"
		  class="dialog-form"
		  :before-close="handlePermissionClose"
		>
		  <div>
		    <el-button size="mini" type="primary" @click="getCheckedKeys1">是</el-button>
		    <el-button size="mini" type="info" @click="handlePermissionClose">否</el-button>
		  </div>
		  <el-tree
		    :data="permissionData"
		    show-checkbox
		    node-key="id"
		    ref="tree"
		    :default-checked-keys="hasPermissionData"
			:check-strictly="true"
		    :props="defaultProps1"> 
			<span class="custom-tree-node" slot-scope="{ node, data }">
				<span>{{ node.label }}</span>
				
			</span>
		  </el-tree>
		</el-dialog>
		
		<!-- 底部 -->
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import {
		api
	} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,
		Popup,
		Form,
		Field,
		PullRefresh,
		Toast,
		List,
		Dialog,
		DropdownMenu,
		DropdownItem,
		Tab,
		Tabs
	} from 'vant';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh).use(Toast).use(List).use(Dialog).use(DropdownMenu)
		.use(DropdownItem).use(Tab).use(Tabs);
	export default {
		components: {
			myNarBar,
			footerTabbar
		},
		data() {
			return {
				list: [],
				isLoading: false,
				loading: false,
				finished: false,
				refreshing: false,
				offset: 20, //滚动条与底部距离小于offset时触发load事件
				queryData: {
					curPage: 1,
					pageSize: 10,
					search: '',
					type: 'N',
				},
				
				disabled: true,
				active: 'N',
				showAddFile: false,
				
				// 上传附件
				uploadForm: {
					businessId: '',
					type: '',
					remark: '',
					fileList: [],
				},
				fileData: [],
				loading: false,
				finished: false,
				typeList: [],
				
				// 权限
				permissionVisible: false,
				permissionData: [],
				hasPermissionData: [],
				defaultProps1: {
					children: 'children',
					label: 'lovName'
				},
				permissionId: '',
				defaultProps1: {
					children: 'children',
					label: 'lovName'
				},
			};
		},
		created() {
			api.queryLovMemberTree1({parentId: '3edb73438d01898598753694281842f8', reflesh: "Y"}).then(res => {
				this.typeList = res;
				this.uploadForm.type = res[0].id;
			});			
			/* {reflesh: 'Y'} */
			api.queryProductTreePermission().then(res => {
				this.permissionData = res
			});
			
			let _this = this
	        document.onkeydown = function(e){
	            let key = window.event.keyCode
	            if(key == 13){
					_this.queryData.curPage = 1;
					_this.list = [];
					_this.finished = false;
					_this.onLoad();
	            }
	        }
		},
		methods: {
			editPermission(item){
				console.log(item)
				this.permissionVisible = true
				this.permissionId = item.id
				if(this.queryData.type == "Y"){
					api.queryRolePermission({roleId: item.id}).then(res => {
						if(res.length == 0){
						  this.hasPermissionData = [];
						}else{
						  this.hasPermissionData = res;
						}
						this.$refs.tree.setCheckedKeys(this.hasPermissionData);
					})
				}
			},
			onSearch() {
				this.queryData.curPage = 1;
				this.list = [];
				this.finished = false;
				this.onLoad();
			},
			getCheckedKeys1(){
				var checkedKeys = this.$refs.tree.getCheckedKeys().join(",");
				var halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys().join(",");
				api.insertFilePermission(this.permissionId, checkedKeys, checkedKeys.concat(",").concat(halfCheckedKeys)).then(() => {
					Toast("配置成功")
				}).catch(() => {
					Toast("配置失败")
				})
			},
			handlePermissionClose() {
				this.hasPermissionData = [];
				this.permissionVisible = false
			},
			openAddFile(){
				this.showAddFile = true
				this.$refs.uploadfiles.clearFiles();
				this.uploadForm.fileList = []
			},
			handleClose() {
			  this.showAddFile = false
			},
			cancleForm() {
			  this.showAddFile = false
			},
			handleChange(file, fileList){
				this.uploadForm.fileList.push(file)
			},
			handleRemove(file, fileList) {
				this.uploadForm.fileList.splice(file,1);
			},
			handleExceed(){
				Toast("超出最大上传文件数量的限制");
				return
			},
			uploadFile(){
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				this.showAddFile = false
				api.uploadFile(this.uploadForm).then(res => {
					this.$refs.uploadfiles.clearFiles();
					this.uploadForm.fileList = []
					Toast.clear();
				})
				this.onRefresh()
			},
			validFile(index, id, valid){
				if(valid == "Y"){
					valid = "N"
				}else{
					valid = "Y"
				}
				api.validFile({'id': id, 'valid': valid}).then(res => {
					Toast(res)
					var a = this.list.find(item => item.id == id)
					a.isValid = valid
					this.list.splice(index, 1, a)
				})
			},
			deleteFile(index, id){
				api.deleteFile({id: id}).then(res => {
					Toast(res)
					this.list.splice(index,1)
				})
			},
			handleUploadClose() {
				this.showAddFile = false
			},
			onCheckTab(name, title){
				this.queryData.type = name
				this.queryData.curPage = 1
				this.onLoad();
			},
			onRefresh(val) {
				this.queryData.curPage = 1;
				this.list = [];
				this.finished = false;
				this.onLoad()
			},
			onLoad() {
				Toast.loading({
					message: '加载中...',
					duration: 10000,
					forbidClick: true,
				});
				api.queryFileList(this.queryData).then(res => {
					if (res == null || res.length === 0) {
						//加载结束
						this.finished = true;
					}
					if (res.length < this.pageSize) {
						//最后一页不足10条的情况
						this.finished = true
					}
					//处理数据
					if (this.queryData.curPage == 1) {
						this.list = res
					} else {
						this.list = this.list.concat(res)
					}
					this.queryData.curPage++;
				}, err => {
					Toast(err.data.msg);
					this.finished = true
				}).finally(() => {
					this.loading = false;
					this.isLoading = false;
					Toast.clear();
				});
			},
			goBack() {
				this.$router.go(-1)
			},
			formatDateHMD(time) {
				if(null == time){
					return null;
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss")
			},
		},
	}
</script>

<style scoped>
	.content {
		background-color: #e8e8e8;
		height: auto;
		padding: 2px;
	}

	.vanCell {
		background-color: rgb(98, 171, 238);
		color: white;
	}

	.vanPopup {
		height: 25%;
		width: 90%;
		border-radius: 8px;
	}

	.vanForm {
		margin-top: 40px;
	}

	.resultsConfirmList {
		width: auto;
		background-color: white;
		padding: 10px 10px;
		border-radius: 10px;
		margin: 5px 3px;
		text-align: left;
	}

	.resultsConfirmList span {
		border-radius: 10px;
		margin: 5px;
		padding: 3px;
		font-size: 10px;
	}

	.title {
		font-size: 20px;
		text-align: left;
		font-weight: 900;
		color: black;
	}

	.trees {
		padding: 0 0 100px 0;
	}

	.resultsConfirmList div {
		padding-top: 2px;
	}

	.icon {
		float: right;
		padding: 2.5px;
	}

	.table-classic-wrapper {
		.el-card {
			min-height: 656px;
			width: 100%;
		}

		.control-btns {
			margin-bottom: 20px;
			/* padding-top: 20px;
		padding-left: 20px; */
		}

		.search-form {
			padding-top: 18px;
			margin-bottom: 15px;
			background-color: #f7f8fb;
		}

		.el-table thead {
			font-weight: 600;

			th {
				background-color: #f2f3f7;
			}
		}

		.dialog-form {
			.el-input {
				width: 70%;
			}
		}
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.inline-block {
		display: inline-block;
		margin: 2px;
	}

	.margin-change {
		display: inline-block;
		margin-left: 10px;
	}
	
	.validateClass {
		float: right;
		background-color: aliceblue;
		padding: 2px 5px;
		color: green;
		border-radius: 8px;
	}
	
	.noneClass {
		float: right;
		background-color: aliceblue;
		padding: 2px 5px;
		color: red;
		border-radius: 8px;
	}
	
	.btn-bg-img {
		width: 80px;
		height: 80px;
		background-size: cover;
	}
	
	.button-box:hover {
		color: white;
		opacity: 1;
	}
	
	.button-box {
		width: 80px;
		border-radius: 50%;
		position: fixed;
		bottom: 80px;
		right: 50px;
		padding-left: 15px;
		padding-top: 8px;
		cursor: pointer;
		opacity: 0.7;
		z-index: 888;
	}
	
	.font-box {
		width: 50px;
		color: #ffffff;
		text-align: center;
		font-size: 30px;
		background-color: #0055ff;
		border-radius: 50%;
	}
	
	.van-icon {
	    top: 1px;
	    position: relative;
	    display: inline-block;
	    font: normal normal normal 14px/1 vant-icon;
	    font-size: inherit;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	}
	
	.el-upload-dragger {
	    background-color: #fff;
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    box-sizing: border-box;
	    width: 300px;
	    height: 150px;
	    text-align: center;
	    position: relative;
	    overflow: hidden;
	}
</style>