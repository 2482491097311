<template>
	<div>
		<div style="padding: 30px;">
			<van-image width="300px" height="300px" :src="url"></van-image>
		</div>
		<div>
			请使用手机或电脑端小程序打开
		</div>
		<div>
			深圳科士达科技股份有限公司版权所有
		</div>

	</div>
</template>
<script>
	import myNarBar from './myNarBar.vue'
	export default {
		name: 'save',
		data() {
			return {
				url: require('../assets/image/barcode.jpg'),
				userAgent: navigator.userAgent,
			};
		},
		created() {},
		methods: {
			goBack() {
				this.$router.go(-1)
			}
		},
		components: {
			myNarBar
		}
	};
</script>

<style scoped>
</style>
