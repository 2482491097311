import Vue from 'vue'
import App from './App.vue'
import router from "./router/index";
import permission from "./router/permission";
import {tip} from "./common/modal";
import store from "./store/store";
import {Card,Select,Option,Form,Radio,Table,TableColumn,DatePicker,Input,Tag,
Button,FormItem,RadioGroup,Col,Tree,Row,Dialog,Upload,Switch,Pagination} from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import touch from 'vue-directive-touch';
Vue.use(touch);
import wx from 'weixin-js-sdk';
Vue.prototype.wx = wx;
import Print from 'vue-print-nb'
Vue.use(Print)

import { 
  Field,Icon,Checkbox,CheckboxGroup
  ,Tabbar,TabbarItem,Grid,GridItem
  ,List,Cell,CellGroup,Divider
  ,Step, Steps,Toast
} from 'vant';
import { Image as VanImage } from 'vant';
Vue.prototype.$tip = tip; // 挂载提示框
Vue.config.productionTip = false
Vue.use(Form);
Vue.use(VanImage);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Dialog).use(Toast);

Vue.use(Checkbox);
Vue.use(CheckboxGroup);

Vue.use(Tabbar);
Vue.use(TabbarItem);

Vue.use(Grid);
Vue.use(GridItem);

Vue.use(List);

Vue.use(Cell);
Vue.use(CellGroup);

Vue.use(Card).use(Divider).use(Step).use(Steps).use(Select).use(Table).use(TableColumn)
.use(Option).use(Radio).use(DatePicker).use(Input).use(Button).use(FormItem).use(Tag)
.use(RadioGroup).use(Col).use(Tree).use(Row).use(Dialog).use(Upload).use(Switch).use(Pagination);

axios.defaults.withCredentials=true;

Date.prototype.format = function(fmt) { 
  var o = { 
     "M+" : this.getMonth()+1,                 //月份 
     "d+" : this.getDate(),                    //日 
     "h+" : this.getHours(),                   //小时 
     "m+" : this.getMinutes(),                 //分 
     "s+" : this.getSeconds(),                 //秒 
     "q+" : Math.floor((this.getMonth()+3)/3), //季度 
     "S"  : this.getMilliseconds()             //毫秒 
 }; 
 if(/(y+)/.test(fmt)) {
         fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
 }
  for(var k in o) {
     if(new RegExp("("+ k +")").test(fmt)){
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
  }
 return fmt; 
}

Vue.prototype.IsMobile = function(){
	let flag = navigator.userAgent.match(
	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	);
	if (!flag) {
		return false;
	}
	return true;
}

Vue.prototype.Permission = function(permissionId){
	if(permissionId == true || permissionId == 'true'){
		return true;
	}
	var a = localStorage.getItem("menuPermission");
	if(null == a || "undefined" == a || "null" == a){
		return false;
	}
	a = JSON.parse(a)
	if(a[permissionId] == permissionId){
		return true;
	}
	return false;
}

new Vue({
  render: h => h(App),
  router,
  permission,
  store
}).$mount('#app')
