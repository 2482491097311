<template>
	<div>
		<!-- 头部 -->
		<van-sticky>
			<myNarBar class="narBarTitle">
				<div slot="left" @click="goBack()">
					<van-icon name="arrow-left" size="24" style="vertical-align: middle;" />
				</div>
				<div slot="title">商品</div>
				<div slot="right">
					<van-icon name="plus" size="24" style="vertical-align: middle;" @click.stop="addfood" />
				</div>
			</myNarBar>
		</van-sticky>
		<div class="content">
			<input v-model="listQuery.name"/>
			<button @click="fetchTreeData">刷新/查询</button>
			<div>
				 <el-table
				    :data="foodList"
				    stripe
				    style="width: 100%">
				    <el-table-column
						prop="attUrl"
						label="图片"
						width="120">
						<template slot-scope="scope">
							<van-image
							  width="50"
							  height="50"
							  :src="scope.row.attUrl"
							/>
						</template>
				    </el-table-column>
				    <el-table-column
						prop="relName"
						label="名称"
						width="120">
				    </el-table-column>
				    <el-table-column
						prop="foodCatName"
						label="类型"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="price"
						label="单价"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="amount"
						label="数量"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="unit"
						label="单位"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="remark"
						label="备注"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="orderNum"
						label="序号"
						width="100">
				    </el-table-column>
				    <el-table-column
						prop="deleteFlag"
						label="状态"
						width="100">
						<template slot-scope="scope">
							<span :class="scope.row.deleteFlag == 'Y'?'unValid':'valid'">{{ scope.row.deleteFlag == "Y" ? "无效" : "有效" }}</span>
						</template>
				    </el-table-column>
					<el-table-column label="操作">
					  <template slot-scope="scope">
						<el-button
							size="mini"
							@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
						<el-button
							size="mini"
							@click="handleDelete(scope.$index, scope.row)">{{ scope.row.deleteFlag == "Y" ? "有效" : "失效" }}</el-button>
					  </template>
					</el-table-column>
				  </el-table>
				  <el-pagination
				    background
				    layout="prev, pager, next"
				    :total="1000">
				  </el-pagination>
			</div>
			
			<el-dialog
				title="新增/编辑"
				:visible.sync="showAdd"
				width="100%"
				class="dialog-form"
			>
				<van-form @submit="onSubmit">
					<van-field 
						v-model="food.relName" 
						label="名称" 
						placeholder="请输入名称" 
						required
						:rules="[{ required: true }]"
						@click="showName = true"
					/>
					<van-popup v-model="showName" position="bottom">
						<van-picker
							show-toolbar
							:columns="nameList"
							@confirm="onName"
							@cancel="showName = false"
						/>
					</van-popup>
					<van-field 
						v-model="food.foodCatName" 
						label="类型" 
						placeholder="请输入类型" 
						required
						:rules="[{ required: true }]"
						@click="showFoodCat = true"
					/>
					<van-popup v-model="showFoodCat" position="bottom">
						<van-picker
							show-toolbar
							:columns="foodCatList"
							@confirm="onFoodCat"
							@cancel="showFoodCat = false"
						/>
					</van-popup>
					<van-field 
						v-model="food.price" 
						label="单价" 
						placeholder="请输入单价" 
						required
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="food.amount" 
						label="数量" 
						placeholder="请输入数量" 
						required
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="food.unit" 
						label="单位" 
						placeholder="请输入单位" 
						required
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="food.orderNum" 
						label="序号" 
						placeholder="请输入序号" 
						required
						:rules="[{ required: true }]"
					/>
					<van-field 
						v-model="food.remark" 
						label="描述" 
						placeholder="请输入描述" 
						required
						type="textarea"
					/>
					<!-- <van-field 
						v-model="food.deleteFlag" 
						label="是否有效(Y/N)" 
						placeholder="请输入Y或者N" 
						required
						:rules="[{ required: true }]"
					/> -->
					<van-field 
						name="uploader" 
						label="文件上传"
						>
					  <template #input>
						<van-image
							width="50"
							height="50"
							:src="food.attUrl"
						/>
					    <van-uploader v-model="fileList"/>
					  </template>
					</van-field>
					<div>
						<van-button round type="info" size="normal">取 消</van-button>
						<van-button round type="info" size="normal" native-type="submit">确 定</van-button>
					</div>
				</van-form>
			</el-dialog>
		</div>
		
		<div>
			<footerTabbar></footerTabbar>
		</div>
	</div>
</template>

<script>
	import addBtn from '../../../components/addBtn.vue'
	import {api} from "../../../common/request/api/index";
	import myNarBar from '../../../components/myNarBar.vue'
	import footerTabbar from '../../../components/footerTabbar.vue'
	import Vue from 'vue';
	import {
		Sticky,Popup,Form,Field,PullRefresh,Toast,List,Dialog,DropdownMenu,DropdownItem,Tab,Tabs,SwipeCell,Image 
	} from 'vant';
import { tip } from '../../../common/modal';
	Vue.use(Sticky).use(Popup).use(Form).use(Field).use(PullRefresh)
	.use(Toast).use(List).use(Dialog).use(DropdownMenu)
	.use(DropdownItem).use(Tab).use(Tabs).use(SwipeCell).use(Image);
	export default {
		name: "food",
		components: {
			myNarBar,
			addBtn,footerTabbar
		},
		data() {
			return {
				listLoading: true,
				foodList: [],
				listQuery: {
					name: '',
				},
				showAdd: false,
				handelType: '',
				
				food: {
					id: '',
					name: '',
					relName: '',
					attUrl: '',
					foodCat: '',
					foodCatName: '',
					price: '',
					amount: '',
					unit: '',
					orderNum: '',
					remark: '',
					deleteFlag: '',
				},
				
				showFoodCat: false,
				foodCatList: [],
				
				fileList: [],
				
				showName: false,
				warehouse: '',
				nameSource: [],
				nameList: [],
			}
		},
		created() {
			this.fetchTreeData();
			
			//发货人,收件人
			api.queryFoodCat(this.listQuery).then(res => {
				this.foodCatSource = res
				this.foodCatList = res.map(function(data){return data.name});
				this.listLoading = false
			})
			
			api.queryLovMember({parentId: '14a10a60d5012552207f83d1bce0352a',optTxt1: '3'}).then(res => {
				this.nameSource = res
				this.nameList = res.map(function(data){return data.lovName});
			})
		},
		methods: {
			goBack() {
				this.$router.go(-1)
			},
			onName(value) {
				var v = this.nameSource.find(item => item.lovName == value);
				this.food.name = v.id;
				this.food.relName = v.lovName;
				this.showName = false;
			},
			onFoodCat(value) {
				var v = this.foodCatSource.find(item => item.name == value);
				this.food.foodCat = v.id;
				this.food.foodCatName = v.name;
				this.showFoodCat = false;
			},
			fetchTreeData() {
			  api.queryFood(this.listQuery).then(res => {
			    this.foodList = res
			    this.listLoading = false
			  }).catch(() => {
			    this.listLoading = false
			  })
			},
			formatDate(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd");
			},
			formatDateHMS(time) {
				if(null == time || "" == time){
					return "";
				}
				return new Date(time).format("yyyy-MM-dd hh:mm:ss");
			},
			handleDelete(index, item){
				var deleteFlag =  item.deleteFlag == 'Y'?'N':'Y';
				api.deleteFood({id: item.id, deleteFlag: deleteFlag}).then(res => {
					Toast("操作成功")
					this.fetchTreeData();
				})
			},
			addfood(){
				this.showAdd = !this.showAdd;
				this.handelType = 'add';
			},
			handleEdit(index, item){
				console.log(index, item);
				this.showAdd = !this.showAdd;
				this.handelType = 'edit';
				this.food = item;
				this.food.createdAt = this.formatDateHMS(this.food.createdAt)
				this.food.updatedAt = this.formatDateHMS(this.food.updatedAt)
			},
			onSubmit(){
				this.foodMethod()
			},
			foodMethod(){
				if(this.handelType == 'add' && this.fileList.length == 0){
					Toast("商品图片不允许为空")
				}else{
					var params = {food: this.food, fileList: this.fileList}
					api.insertUpdateFood(params).then(res => {
						Toast("操作成功")
						this.showAdd = !this.showAdd;
						this.fetchTreeData();
					}, err => {
						if (err) tip(err.data.msg);
					})
				}
			}
		},
	};
</script>

<style scoped>
	.unValid {
		color: red;
	}
	.content {
		overflow-x: hidden;
		padding: 0px 0px 100px;
	}
</style>
