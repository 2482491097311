import axios from 'axios';
import router from '@/router/index';
import store from '@/store/store';
import {
	tip
} from "../modal";

const toLogin = () => {
	console.log(123)
	router.replace({
		path: '/login',
		query: {
			redirect: router.currentRoute.fullPath
		}
	});
}
const toHome = () => {
	console.log(1234)
	router.replace({
		path: '/product',
		query: {
			redirect: router.currentRoute.fullPath
		}
	});
}

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
	console.log(status, other)
	// 状态码判断
	switch (status) {
		// 401: 未登录状态，跳转登录页
		case 401:
			toLogin();
			break;
			// 403 token过期
			// 清除token并跳转登录页
		case 402:
			toHome();
			break;
			// 403 token过期
			// 清除token并跳转登录页
		
		case 403:
			tip('登录过期，请重新登录');
			localStorage.removeItem('userObject');
			// store.commit('token', null);
			setTimeout(() => {
				toLogin();
			}, 1000);
			break;
			// 404请求不存在
		case 404:
			tip('请求的资源不存在');
			break;
		default:
			console.log(other);
			tip(other);
	}
}
// 创建axios实例
var instance = axios.create({
	timeout: 1000 * 180,
	withCredentials:true
});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/** 
 * 请求拦截器 
 */
instance.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token');
		if(token) {// 在发起请求前，给所有请求的头部添加
			config.headers.common['token'] = token
		}
		return config;
	},
	error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
	res => {
		store.commit('changeNetworkStatus', true);
		var url = res.config.url
		if (res.status === 200 && url.indexOf(".do") != -1) {
			if (res.data && res.data.status == 0) {
				return Promise.resolve(res.data.data);
			} else if (res.data && res.data.status == 1) {
				return Promise.reject(res);
			} else if (res.status === 200 && res.data && res.data.status == 401) {
				errorHandle(res.data.status, res.data.msg);
				return Promise.reject(res);
			} else if (res.status === 200 && res.data && res.data.status == 402) {
				errorHandle(res.data.status, res.data.msg);
				return Promise.reject(res);
			} else {
				console.info("response状态未定义。");
				return Promise.reject(res);
			}
		} else if (url.indexOf(".loginFree") != -1) {
			if (res.data && res.data.status == 0) {
				return Promise.resolve(res.data.data);
			} else if (res.data && res.data.status == 1) {
				return Promise.reject(res);
			} else if (res.status === 200 && res.data && res.data.status == 401) {
				errorHandle(res.data.status, res.data.msg);
				return Promise.reject(res);
			} else if (res.status === 200 && res.data && res.data.status == 402) {
				errorHandle(res.data.status, res.data.msg);
				return Promise.reject(res);
			} else {
				return Promise.resolve(res);
			}
		}  else if (url.indexOf("video") != -1) {
			return Promise.resolve(res.data);
		} else {
			console.info(res);
			return Promise.reject(res);
		}
	},
	error => {
		const {
			response
		} = error;
		if (response) {
			errorHandle(response.status, response.data.message);
			return Promise.reject(response);
		} else {
			store.commit('changeNetworkStatus', false);
			return Promise.reject(response);
		}
	}
);

export default instance;
