<template>
  <div class="navBar">
      <div class="left"><slot name="left" left-arrow></slot></div>
      <div class="title"><slot name="title"></slot></div>
      <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Sticky } from 'vant';
Vue.use(Sticky)
export default {
  name: 'myNarBar'
}
</script>

<style>
.navBar{
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 44px;
  line-height: 44px;
  box-shadow: 0px 1px 1px rgba(100, 100, 100, 0.15);
}
.left{
  width: 60px;
  color: black;
}
.right{
  width: 60px;
  color: black;
}
.title{
  flex: 1;
  color: black;
}
</style>