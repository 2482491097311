<template>
	<div>
		<van-tabbar v-model="active" route :safe-area-inset-bottom="true" class="footerTabbar">
			<van-tabbar-item icon="home-o" replace to="/index">首页</van-tabbar-item>
			<van-tabbar-item icon="shop-o" replace to="/shop">订购</van-tabbar-item>
			<van-tabbar-item icon="orders-o" replace to="/order">订单</van-tabbar-item>
			<van-tabbar-item icon="friends-o" replace to="/person">会员</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {api} from "../common/request/api/index";
	import {
		Tabbar,
		TabbarItem
	} from 'vant';
	import {
		Badge,Toast
	} from 'vant';
	Vue.use(Tabbar).use(TabbarItem).use(Badge).use(Toast);

	export default {
		name: 'footerTabbar',
		data() {
			return {
				active: 0,
				showSystem: false,
			};
		},
		created() {
		},
	};
</script>

<style scoped>
	.footerTabbar {
		padding: 20px 0px;
		z-index: 7;
	}
</style>

